import { useSelector } from 'react-redux'
import { getTranslation, openModal } from '../app/appSlice'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import { useState } from 'react'
import _ from 'lodash'
import Error from '../components/Error'
import { useRegisterMutation } from '../features/auth/authAPI'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const Register = () => {   
    const usernameLabel = useSelector(getTranslation('register.username.label'))
    const emailLabel = useSelector(getTranslation('register.email.label'))
    const passwordLabel = useSelector(getTranslation('register.password.label'))
    const createAccBtnLabel = useSelector(getTranslation('register.creatAccount.btn'))
    const navigate = useNavigate()
    const [customError, setCustomError] = useState(null)
    const [formData, updateFormData] = useState({})
    const [ register, data ] = useRegisterMutation() 
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({})
    const dispatch = useDispatch()


    const handleFormChange = (key, value) => {
        setValidated(false)
        setError(_.omit(error, [key]))
        let update = {}
        update[key] = value
        updateFormData(_.assign({}, formData, update))                                   
    }

     const handleSubmit = e => {      
        e.preventDefault()  
        e.stopPropagation()
        const form = e.currentTarget       
        if(form.checkValidity() && _.isEmpty(error)) {
            register(_.pick(formData, ['email', 'password', 'username']))
            .then(response => {
                if(_.get(response, ['data', 'success'])) {
                        updateFormData({})
                        setError({})
                        navigate('/')
                        dispatch(openModal({'modalName': 'login'}))
                }else if(_.get(response, ['error', 'status']) === 409) {                
                    setError(_.assign({}, error, {email: 'Account already exists for this email'}))
                }      
                setValidated(false)  
            })
        }
        setValidated(true)
    }

    return (
        <>
            <Container className="register">
                <Row>
                    <Col  
                        xs={{span: 10, offset: 1}}
                        md={{span: 6, offset: 3}}
                        lg={{span: 4, offset: 4}}
                    >
                        <Form 
                            noValidate 
                            validated={validated} 
                            id="edit-team-form"
                            onSubmit={handleSubmit}
                        >
                            <Form.Group className="mb-3" controlId="formUsername">
                                <Form.Label>{usernameLabel + ' :'}</Form.Label>
                                <Form.Control 
                                    value={_.get(formData, ['username']) || ''}
                                    onChange={e => handleFormChange('username',  _.get(e, ['target', 'value']))}
                                    required        
                                    name="username"                                       
                                />   
                                <Form.Control.Feedback type="invalid">
                                    Please enter username
                                </Form.Control.Feedback>                         
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>{emailLabel + ' :'}</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    value={_.get(formData, ['email']) || ''}
                                    required
                                    name="email"
                                    onChange={e => handleFormChange('email', _.get(e, ['target', 'value']))}
                                    isInvalid={_.get(error, ['email'])}
                                />    
                                <Form.Control.Feedback type="invalid">
                                    {_.get(error, ['email']) || 'Please enter email address'}
                                </Form.Control.Feedback>                        
                            </Form.Group>                           
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>{passwordLabel + ' :'}</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    value={_.get(formData, ['password']) || ''}
                                    required
                                    name="password"
                                    onChange={e => handleFormChange('password', _.get(e, ['target', 'value']))}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a password
                                </Form.Control.Feedback>
                            </Form.Group>                                        
                            <Button 
                                variant="secondary" 
                                type="submit"                                
                            >
                                {createAccBtnLabel}
                            </Button>                               
                        </Form>     
                    </Col>                
                </Row>
            </Container>        
        </>
    )
}

export default Register