import Teams from '../features/team/Teams'
import Landing from './Landing'
import { getCurrentUser } from '../features/auth/authSlice'
import { useSelector } from 'react-redux'

const HomeScreen = () => {
    const user = useSelector(getCurrentUser)
    return ( user ? <Teams /> : <Landing /> )
}   

export default HomeScreen