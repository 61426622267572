import { configureStore } from '@reduxjs/toolkit'
import appReducer from './appSlice'
import { apiSlice } from "./apiSlice"
import authReducer from '../features/auth/authSlice'
import { userAPI } from '../features/user/userAPI'
import { contactAPI } from '../features/contact/contactAPI'
import  matchDayReducer from '../features/matchDay/matchDaySlice'
import { matchDayAPI } from '../features/matchDay/matchDayAPI'
import fomationPickerReducer from '../features/formationPicker/formationPickerSlice'
import { teamAPI } from '../features/team/teamAPI'
import { kitAPI } from '../features/kit/kitAPI'
import _ from 'lodash'


const store = configureStore({
	reducer: {
		appSlice: appReducer,
		[apiSlice.reducerPath]: apiSlice.reducer,
		authSlice: authReducer,		
		[userAPI.reducerPath]: userAPI.reducer,
		matchDaySlice: matchDayReducer,
		[matchDayAPI.reducerPath]: matchDayAPI.reducer,
		formationPickerSlice: fomationPickerReducer,
		[teamAPI.reducerPath]: teamAPI.reducer,
		[kitAPI.reducerPath]: kitAPI.reducer,
	},
	middleware: getDefaultMiddleware => 
		_.concat(
    		getDefaultMiddleware({
			    immutableCheck: { warnAfter: 50 },
			    serializableCheck: { warnAfter: 50 },
  			}),
			[				
				userAPI.middleware,
				contactAPI.middleware,
				matchDayAPI.middleware,
				teamAPI.middleware
			]
    	),
})
export default store