import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { unsetCredentials } from '../features/auth/authSlice'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import logo from '../assets/img/Logo.png'
import { getTheme, getTranslation, openModal } from '../app/appSlice'
import { getCurrentUser } from '../features/auth/authSlice'
import { useLogoutMutation } from '../features/auth/authAPI'
import _ from 'lodash'
import { AiOutlineUser } from "react-icons/ai";
import { LinkContainer } from 'react-router-bootstrap'
import { useGetCurrentTeamQuery } from '../features/team/teamAPI'

const Header = () => {
    const dispatch = useDispatch()
    const theme = useSelector(getTheme)
    const logoutBtnLabel = useSelector(getTranslation('nav.btn.logout'))
    const loginBtnLabel = useSelector(getTranslation('login.btn'))   
    const user = useSelector(getCurrentUser)
    const navigate = useNavigate()
    const [ logout ] = useLogoutMutation()
    const team = _.get(useGetCurrentTeamQuery({}, {skip: !user}), ['data', 'team'])
    
    const handleLogout = () => {      
        logout()
        .then(result => {
            if(_.get(result, ['data', 'success'])) {
                dispatch(unsetCredentials())
                navigate("/")
            }                
        })
        .catch(error => console.error('Logout Error', error))
    }


    return (
        <>  
            {
                <div className="user-bar">
                    {
                        user ? 
                            <Nav className="user-nav">
                                <AiOutlineUser className="user-icon"/>
                                <NavDropdown title={user.username} menuVariant="dark" drop="down" className="user-menu">
                                    <NavDropdown.Item onClick={handleLogout}>{logoutBtnLabel}</NavDropdown.Item>
                                </NavDropdown>  
                            </Nav>        
                        :
                        <div 
                            className="signin-btn"
                            onClick={() => dispatch(openModal({modalName: 'login'}))}
                        >
                            {loginBtnLabel}
                        </div>
                    }                    
                </div> 
            }            
            <div className="header">            
                <Navbar variant={theme} expand="lg">
                    <Container fluid>
                        <Navbar.Brand>
                            <img
                                src={logo}                        
                                className="d-inline-block align-top"
                                alt="Logo"
                            />
                        </Navbar.Brand>  
                        <Navbar.Toggle aria-controls="pi-navbar-nav"/>   
                        <Navbar.Collapse id="pi-navbar-nav">     
                            <Nav className="mr-auto">
                                {
                                    user ? 
                                        <>    
                                            <LinkContainer to="/">
                                                <Nav.Link>Team</Nav.Link>
                                            </LinkContainer>                           
                                            <LinkContainer to="/match-day">
                                                <Nav.Link disabled={!team}>Match Day</Nav.Link>
                                            </LinkContainer>       
                                        </>
                                    :
                                        <>
                                            <LinkContainer to="/">
                                                <Nav.Link>Home</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to="/about">
                                                <Nav.Link>About</Nav.Link>
                                            </LinkContainer>                                       
                                            <LinkContainer to="/contact">
                                                <Nav.Link>Contact Us</Nav.Link>
                                            </LinkContainer>
                                        </>
                                }
                            </Nav>  
                        </Navbar.Collapse>                              
                    </Container>       
                </Navbar>            
            </div>
        </>
    )
}

export default Header