import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { enIE } from '../assets/languages/en-IE'

const initialState = {
  theme: 'dark',  
  languages: [
      {
        name: 'en-IE',
        label: 'English (IE)',
        translations: enIE,
        active: true,
        country: 'IE',  
        default: true    
      }     
    ]
}

const appSlice = createSlice({  
    name: 'appSlice',  
    initialState,  
    reducers: {
        setTheme: (state, theme) => { 
            state.theme = theme.payload 
            if(theme.payload === 'light') {
                document.body.classList.add('light-mode');
            }else {
                document.body.classList.remove('light-mode');
            }
        },
        setLanguage: (state, language) => {
            state.languages = _.map(state.languages, lang => {
                lang.active = language.payload.name === lang.name
                return lang;
            })
        },
        closeModal: state => { 
            state.modal = undefined
            state.modalData = undefined
        },
        openModal: (state, action) => { 
            const { modalName, modalData } = action.payload
            state.modalData = modalData
            state.modal = modalName 
        }   
    }
})

export default appSlice.reducer;
export const getTheme = state => state.appSlice.theme
export const getLanguages = state => state.appSlice.languages
export const getLanguageCode = state => _.first(_.split(_.get(_.find(state.appSlice.languages, 'active'), ['name']), '-'))
export const getTranslation = path => state => _.get(_.find(state.appSlice.languages, 'active'), ['translations', path]) || _.get(_.find(state.appSlice.languages, 'default'), ['translations', path])
export const isModalOpen = modal => state => state.appSlice.modal === modal
export const getModalData = modal => state => state.appSlice.modal === modal ? state.appSlice.modalData : undefined
export const { 
  setTheme, 
  setLanguage,
  closeModal,
  openModal
} = appSlice.actions;