import { 
        useGetUserTeamsQuery,          
        useSetCurrentTeamMutation, 
        useGetCurrentTeamQuery,
        useGetRatingsQuery        
} from './teamAPI'
import { useGetKitsQuery } from '../kit/kitAPI'
import _ from 'lodash'
import Select from 'react-select'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ListGroup from 'react-bootstrap/ListGroup';
import selectStyles from '../../assets/constants/selectStyles'
import { useState, useEffect } from 'react'
import EditPlayerModal from './EditPlayerModal'
import EditTeamModal from './EditTeamModal'
import EditKitModal from './EditKitModal'
import { openModal } from '../../app/appSlice'
import { useDispatch } from 'react-redux'
import Jersey from '../kit/Jersey'

const Teams = () => {
    const teams = _.get(useGetUserTeamsQuery(), ['data', 'teams'])
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])
    const ratings = _.get(useGetRatingsQuery({args: {}}, {skip: !team}), ['data'])
    const [ setCurrentTeam ] = useSetCurrentTeamMutation()    
    const dispatch = useDispatch()
    const [players, setPlayers] = useState([])
    const kits = _.get(useGetKitsQuery({}, {skip: !team}), ['data', 'kits'])
    
    useEffect(() => {
        if(team && ratings) {
            const players = _.map(team.players, p => _.assign({}, p, {rating: ratings[p.id] || 0}))
            setPlayers(_.orderBy(players, ['rating'], ['desc']))    
        }        
    }, [team, ratings])

    return (
        <>
            <Container className="teams">
                <Row>
                    <Col 
                        xs={{span: 10, offset: 1}}
                        md={{span: 6, offset: 3}}
                        lg={{span: 4, offset: 4}}                        
                    >
                        {
                            _.isEmpty(teams) ? 
                                    <div className="empty-list teams">No Teams</div>
                                :
                                    <Select 
                                        isSearchable
                                        options={_.map(teams, team => {return{label: team.name, value: team}})} 
                                        placeholder="Select Team"  
                                        onChange={selected => setCurrentTeam(selected.value.id)}     
                                        className="pi-select team"
                                        value = {team ? {label: team.name, value: team}: undefined}
                                        styles={selectStyles} 
                                        classNamePrefix="select"     
                                    />
                        }

                        <Button 
                            className="add-btn team"
                            onClick={() => dispatch(openModal({modalName: 'editTeam'}))}
                            variant="outline-primary"
                        >
                            Add Team
                        </Button>
                        {
                            team &&
                                 <Button 
                                    className="edit-btn team"
                                    onClick={() => dispatch(openModal({
                                        modalName: 'editTeam',
                                        modalData: {team}
                                    }))}
                                    variant="outline-primary"
                                >
                                    Edit Team
                                </Button>   
                        }                       
                    </Col>
                </Row>
                <Row className="py-5">
                    <Col 
                        xs={{span: 10, offset: 1}}
                        md={{span: 6, offset: 3}}
                        lg={{span: 4, offset: 4}}
                    >
                        { 
                            team &&
                                <>
                                    {
                                        _.isEmpty(players) ?
                                            <div className="empty-list players">Team has no players</div>
                                        :
                                            <ListGroup className="players">
                                                {
                                                    _.map(players, player => (
                                                        <ListGroup.Item 
                                                            key={player.id}
                                                                onClick={() => dispatch(openModal({
                                                                    modalName: 'editPlayer',
                                                                    modalData: {player}
                                                                }))}
                                                        >
                                                            <div className="name">{player.name}</div>
                                                            <div className="rating">{player.rating === 0 ? '' : player.rating}</div>
                                                        </ListGroup.Item>
                                                    ))
                                                }
                                            </ListGroup>                                       
                                    }                                    
                                    <Button 
                                        onClick={() => dispatch(openModal({modalName: 'editPlayer'}))}
                                        className="add-btn player"
                                        variant="outline-primary"
                                    >
                                        Add Player
                                    </Button>                                
                                </>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col 
                        xs={{span: 10, offset: 1}}
                        md={{span: 6, offset: 3}}
                        lg={{span: 4, offset: 4}}
                    >
                        {
                            team && 
                                <>
                                    {
                                        _.isEmpty(kits) ? 
                                            <div className="empty-list kits">Team has no kits</div>
                                        :
                                            <div className="kits">
                                                {                                                
                                                    _.map(kits, kit => (
                                                        <div 
                                                            className="kit" 
                                                            key={kit.id}
                                                            onClick={() => dispatch(openModal({
                                                                        modalName: 'editKit',
                                                                        modalData: {kit}
                                                                }))}
                                                        >
                                                            <Jersey 
                                                                key={kit.id}
                                                                color1={kit.color1}
                                                                color2={kit.color2}
                                                                variant={kit.variant}                                                                
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }     

                                    <Button 
                                        className="add-btn kit"
                                        onClick={() => dispatch(openModal({modalName: 'editKit'}))}
                                        variant="outline-primary"
                                    >
                                        Add Kit
                                    </Button> 
                                </>
                        }                                    
                    </Col>
                </Row>
            </Container>            
            <EditTeamModal />
            <EditPlayerModal />
            <EditKitModal />
        </>
    )
}
  

export default Teams