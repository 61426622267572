import { useSelector } from 'react-redux'
import { getTranslation } from '../app/appSlice'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import Error from '../components/Error'
import { useUpdatePasswordMutation } from '../features/auth/authAPI'
import { useNavigate } from 'react-router-dom'
import { getResetCode } from '../features/auth/authSlice'


const UpdatePassword = () => {   

    const [form, updateForm] = useState({password: '', passwordConfirm: ''}); 
    const passwordLabel = useSelector(getTranslation('register.password.label'))
    const passwordConfirmLabel = useSelector(getTranslation('register.passwordConfirm.label'))
    const instructions = useSelector(getTranslation('updatePassword.instructions'))
    const setPasswordLabel = useSelector(getTranslation('updatePassword.password.label'))
    const [customError, setCustomError] = useState(null)
    const resetCode = useSelector(getResetCode)
    const [ updatePassword, data ] = useUpdatePasswordMutation();
    const navigate = useNavigate()

    const submitForm = e => {    
    	e.preventDefault()  
        if (form.password !== form.passwordConfirm) {
            setCustomError('Password mismatch')
            return
        }
        updatePassword({
            password: form.password, 
            reset_code: resetCode || undefined
        })
    }

    useEffect(() => {        
        if (_.get(data, ['isSuccess'])) {              
            navigate('/')
        }
        else if (_.get(data, ['isError'])) {
            if(_.get(data, ['error', 'status']) === 400) {
                setCustomError('Bad request')
            }
        }
    }, [data, navigate])

    return (
        <Container>
            <Row>
                <Col>
                    {instructions}
                    <Form onSubmit={submitForm}>                        
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>{passwordLabel + ' :'}</Form.Label>
                            <Form.Control 
                                type="password" 
                                size="lg"
                                value={form.password}
                                onChange={e => updateForm(_.assign({}, form, { password: _.get(e, ['target', 'value']) }))}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formConfirmPassword">
                            <Form.Label>{passwordConfirmLabel + ' :'}</Form.Label>
                            <Form.Control 
                                type="password" 
                                size="lg"
                                value={form.passwordConfirm}
                                onChange={e => updateForm(_.assign({}, form, { passwordConfirm: _.get(e, ['target', 'value']) }))}
                            />
                        </Form.Group> 	   
                        <div>
                            {customError && <Error>{customError}</Error>}
                        </div>                        
                        <Button variant="secondary" type="submit" size="lg">
                            {setPasswordLabel}
                        </Button>                        
                    </Form>      
                </Col>                
            </Row>
        </Container>
    )
}

export default UpdatePassword