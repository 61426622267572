import { 
	useGetMatchEventsQuery,
    useGetCurrentMatchQuery,
} from './matchDayAPI'
import _ from 'lodash'
import { ListGroup } from 'react-bootstrap'
import goalSetups from '../../assets/constants/goalSetups'
import { FaRegEdit } from "react-icons/fa";
import classnames from 'classnames'

const getText = (matchEvent, opponent) => {	
    let text = _.get(matchEvent, ['performance', 'player', 'name']) || opponent
    switch (matchEvent.event_type) {
        case 1: text += ' scored a goal'
            if(matchEvent.goal_setup === 1) {
                text += ' in open play'
            }else {
                text += ' from a ' +  _.get(goalSetups[matchEvent.goal_setup - 1], ['label'])
            }
            if(matchEvent.assisted_by) {
                text += ', assisted by ' + matchEvent.assisted_by.name
            }
            break;
        case 2: text += ' given a red card'
            break;
        case 3: text += ' given a yellow card'
            break;
        case 4: text += ' subsitituted on for ' + matchEvent.subbed_for.name
        	break;
        case 5: text += ' missed a penalty'
        	break;
        case 6: text += ' saved a penalty'
        	break;
        case 7: text += ' failed to save a penalty'
        	break        
        default:
    }
    return text;
}

const MatchReport = () => {
	const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
	const match_events = _.get(useGetMatchEventsQuery(match.id, {skip: !match}), ['data', 'match_events'])
	
	return (
		<div className="match-report">
			<ListGroup>
				{
					_.map(match_events, match_event => (
						<ListGroup.Item className="match-event" key={match_event.id}>
							<div className="time">
								{`${match_event.event_time}${match_event.extra_time ? ' xt' : ''}`}

							</div>
							<div className={classnames(
								'text', 
								{'good': ( _.indexOf([1, 6], match_event.event_type) !== -1 && _.get(match_event, ['performance_id']) ) || ( _.indexOf([2, 3, 5, 7], match_event.event_type) !== -1 && !_.get(match_event, ['performance_id']) )},
								{'bad': ( _.indexOf([2, 3, 5, 7], match_event.event_type) !== -1 && _.get(match_event, ['performance_id']) ) || ( _.indexOf([1, 6], match_event.event_type) !== -1 && !_.get(match_event, ['performance_id']) )}
							)}>
								{getText(match_event, match.opponent)}								
							</div>							
							<FaRegEdit  
								className="edit-icon" 
								onClick={() => console.log('edit', match_event)}
							/>
						</ListGroup.Item>
					))
				}
			</ListGroup>
		</div>
	)
}

export default MatchReport