const zones = [ 
	{ label: { 5: 'GK', 7: 'GK', 9: 'GK', 11: 'GK' }, x: 130, y: 450, width: 90, height: 90 },	
	{ label: { 5: 'DC', 7: 'DC', 9: 'LB', 11: 'LB' }, x: 0, y: 360, width: 60, height: 90 },
	{ label: { 5: 'DC', 7: 'DC', 9: 'CB', 11: 'CB' }, x: 60, y: 360, width: 70, height: 90 },
	{ label: { 5: 'DC', 7: 'DC', 9: 'CB', 11: 'CB' }, x: 130, y: 360, width: 90, height: 90 },
	{ label: { 5: 'DC', 7: 'DC', 9: 'CB', 11: 'CB' }, x: 220, y: 360, width: 70, height: 90 },
	{ label: { 5: 'DC', 7: 'DC', 9: 'RB', 11: 'RB' }, x: 290, y: 360, width: 60, height: 90 },
	{ label: { 5: 'CM', 7: 'DM', 9: 'LWB', 11: 'LWB' }, x: 0, y: 270, width: 60, height: 90 },
	{ label: { 5: 'CM', 7: 'DM', 9: 'CDM', 11: 'CDM' }, x: 60, y: 270, width: 70, height: 90 },
	{ label: { 5: 'CM', 7: 'DM', 9: 'CDM', 11: 'CDM' }, x: 130, y: 270, width: 90, height: 90 },
	{ label: { 5: 'CM', 7: 'DM', 9: 'CDM', 11: 'CDM' }, x: 220, y: 270, width: 70, height: 90 },
	{ label: { 5: 'CM', 7: 'DM', 9: 'RWB', 11: 'RWB' }, x: 290, y: 270, width: 60, height: 90 },
	{ label: { 5: 'CM', 7: 'LM', 9: 'LM', 11: 'LM' }, x: 0, y: 170, width: 60, height: 100 },
	{ label: { 5: 'CM', 7: 'CM', 9: 'CM', 11: 'CM' }, x: 60, y: 200, width: 70, height: 70 },
	{ label: { 5: 'CM', 7: 'CM', 9: 'CM', 11: 'CM' }, x: 130, y: 200, width: 90, height: 70 },
	{ label: { 5: 'CM', 7: 'CM', 9: 'CM', 11: 'CM' }, x: 220, y: 200, width: 70, height: 70 },
	{ label: { 5: 'CM', 7: 'RM', 9: 'RM', 11: 'RM' }, x: 290, y: 170, width: 60, height: 100 },
	{ label: { 5: 'CM', 7: 'AM', 9: 'LAM', 11: 'LAM' }, x: 60, y: 130, width: 70, height: 70 },
	{ label: { 5: 'CM', 7: 'AM', 9: 'CAM', 11: 'CAM' }, x: 130, y: 130, width: 90, height: 70 },
	{ label: { 5: 'CM', 7: 'AM', 9: 'RAM', 11: 'RAM' }, x: 220, y: 130, width: 70, height: 70 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'CF', 11: 'CF' }, x: 100, y: 80, width: 40, height: 50 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'CF', 11: 'CF' }, x: 140, y: 60, width: 70, height: 70 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'CF', 11: 'CF' }, x: 210, y: 80, width: 40, height: 50 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'ST', 11: 'LW' }, x: 0, y: 0, width: 60, height: 170 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'LF', 11: 'LF' }, x: 60, y: 0, width: 40, height: 130 },
	{ label: { 5: 'CF', 7: 'ST', 9: 'ST', 11: 'ST' }, x: 100, y: 0, width: 40, height: 80 },
	{ label: { 5: 'CF', 7: 'ST', 9: 'ST', 11: 'ST' }, x: 140, y: 0, width: 70, height: 60 },
	{ label: { 5: 'CF', 7: 'ST', 9: 'ST', 11: 'ST' }, x: 210, y: 0, width: 40, height: 80 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'RF', 11: 'RF' }, x: 250, y: 0, width: 40, height: 130 },
	{ label: { 5: 'CF', 7: 'CF', 9: 'ST', 11: 'RW' }, x: 290, y: 0, width: 60, height: 170 }
]

export default zones