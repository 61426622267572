import { apiSlice } from "../../app/apiSlice";

export const matchDayAPI = apiSlice.injectEndpoints({    
    tagTypes: ['Match', 'Matches', 'Formations', 'Formation', 'Team', 'MatchEvents'],
    endpoints: builder => ({      
        getCurrentMatch: builder.query({
            query: () => 'match/current', 
            method: 'GET' ,
            providesTags: ['Match']     
        }),
        setCurrentMatch: builder.mutation({
            query: req => ({
                url: 'match/current',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['Match', 'Formations', 'Formation']
        }),        
        createMatch: builder.mutation({
            query: match => ({
                url: 'match',
                method: 'POST',              
                body: match    
            }),
            invalidatesTags: ['Match', 'Team', 'Matches']
        }),   
        updateMatch: builder.mutation({
            query: req => ({
                url: `match/${req.match_id}`,
                method: 'PATCH',
                body: req.match
            }),
            invalidatesTags: ['Match', 'MatchEvents']
        }),    
        getMatches: builder.query({
            query: () => 'match',
            method: 'GET',
            providesTags: ['Matches']      
        }),  
        getFormations: builder.query({
            query: params => {
                const { team_id, num_players, match_id } = params
                return {
                    url: 'formation',
                    method: 'GET',
                    params: { team_id, num_players, match_id }                              
                }        
            },
            providesTags: ['Formations']
        }),  
        addFormation: builder.mutation({
            query: formation => ({
                url: 'formation',
                method: 'POST',
                body: formation                
            }),
            invalidatesTags: ['Formations']
        }),
        updateFormation: builder.mutation({
            query: req => ({
                url: `formation/${req.formation.id}`,
                method: 'PATCH',
                body: {formation: req.formation}
            }),
            invalidatesTags: ['Formations', 'Formation']
        }),
        setCurrentFormation: builder.mutation({
            query: req => {
                const { match_id, formation_id } = req
                return {
                    url: `match/${match_id}/formation`,
                    method: 'POST',
                    body: {formation_id}               
                }
            },
            invalidatesTags: ['Formation']
        }),
        getCurrentFormation: builder.query({
            query: match_id => `match/formation`,
            method: 'GET',
            providesTags: ['Formation']             
        }),  
        updatePosition: builder.mutation({
            query: req => ({
                url: `position/${req.position.id}`,
                method: 'PATCH',
                body: req
            }),
            invalidatesTags: ['Formations', 'Formation']
        }),
        assignPlayers: builder.mutation({
            query: positions => ({
                url: `position/player`,
                method: 'POST',
                body: {positions}
            }),
            invalidatesTags: ['Formations', 'Formation', 'MatchEvents', 'Match']
        }),
        addMatchEvent:  builder.mutation({
            query: req => ({
                url: 'match_event',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['Match', 'Matches', 'MatchEvents']
        }),   
        getMatchEvents: builder.query({
            query: match_id => {
                return {
                    url: 'match_event',
                    method: 'GET',
                    params: {match_id}                              
                }        
            },
            providesTags: ['MatchEvents']
        }),  
    })
})

export const  {     
    useGetCurrentMatchQuery,  
    useCreateMatchMutation,    
    useAddSquadMemberMutation,
    useRemoveSquadMemberMutation,
    useGetSquadQuery,
    useGetFormationsQuery,
    useAddFormationMutation,
    useUpdateSquadMemberMutation,
    useSetCurrentFormationMutation,
    useGetCurrentFormationQuery,
    useUpdatePositionMutation,
    useUpdateFormationMutation,
    useAssignPlayersMutation,
    useUpdateMatchMutation,
    useSetCurrentMatchMutation,
    useGetMatchesQuery,
    useAddMatchEventMutation,
    useGetMatchEventsQuery
} = matchDayAPI