const eventTypes = [
	{value: 'goal', label: 'Goal', key: 1},
	{value: 'red_card', label: 'Red Card', key: 2},
    {value: 'yellow_card', label: 'Yellow Card', key: 3},
    {value: 'sub', label: 'Substitution', key: 4}, 
    {value: 'pen_missed', label: 'Penalty Missed', key: 5},
    {value: 'pen_saved', label: 'Penalty Saved', key: 6},
    {value: 'pen_not_saved', label: 'Penalty Not Saved', key: 7},
]

export default eventTypes