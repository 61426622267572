import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentMatchQuery, useAssignPlayersMutation } from './matchDayAPI'
import Select from 'react-select'
import selectStyles from '../../assets/constants/selectStyles'
import { useGetCurrentTeamQuery } from '../team/teamAPI'

const getEarliestEventTime = (playerOn, playerOff) => {
    let eventTime
    const offPerformanceStart = playerOff.currentPerf.time_on
    if(!_.isEmpty(playerOn.performances)) {
        const onLastTimeOff = _.get(_.head(_.orderBy(playerOn.performances, ['time_off'], ['desc'])), ['time_off'])
        if(offPerformanceStart < onLastTimeOff) {
            eventTime = ( onLastTimeOff - offPerformanceStart ) + offPerformanceStart
        }else {
            eventTime = onLastTimeOff
        }     
    }else {
        eventTime = offPerformanceStart || 1
    }     
    return eventTime               
}


const SubModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('sub'))
    const modalData = useSelector(getModalData('sub'))
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])    
    const [formData, updateFormData] = useState({})
    const [players, updatePlayers] = useState([])
    const [ assignPlayers ] = useAssignPlayersMutation()    

    useEffect(() => {
        if(modalData && _.isEmpty(formData) && match && _.isEmpty(players) && _.get(team, ['players'])) {   
            const players = _.map(team.players, player => {
                const performances = _.filter(match.performances, perf => player.id === perf.player_id) || []
                return _.assign({}, player, { 
                    performances,
                    currentPerf: _.find(performances, perf => _.get(perf, ['time_off']) === match.match_time)
                })
            })    
            updatePlayers(players) 
            const playerOn = _.find(players, player => player.id === modalData.playerOn.id) 
            const playerOff = _.find(players, player => player.id === modalData.playerOff.id)            
            const event_time = getEarliestEventTime(playerOn, playerOff)
            const formData = { playerOn, playerOff, event_time }
            updateFormData(formData)
        }
    }, [formData, modalData, match, team, players])
    
    const handlePlayerOnSelect = selectedPlayer => {
        const event_time = getEarliestEventTime(selectedPlayer, formData.playerOff)
        updateFormData(_.assign({}, formData, {playerOn: selectedPlayer, event_time}))
    }

    const handlePlayerOffSelect = selectedPlayer => {
        const event_time = getEarliestEventTime(formData.playerOn, selectedPlayer)
        updateFormData(_.assign({}, formData, {playerOff: selectedPlayer, event_time}))
    }        
    
    const handleTimeUpdate = minutes => {  
        const event_time = Number(minutes)
        const extra_time = formData.extra_time ? false : formData.extra_time
        if(event_time > _.get(formData, ['playerOff', 'currentPerf', 'time_off']) || event_time < _.get(formData, ['playerOff', 'currentPerf', 'time_on'])) {
            return           
        }
        if(_.find(_.get(formData, ['playerOn', 'performances']), perf => perf.time_on < event_time && perf.time_off > event_time)) {
            return
        }
        updateFormData(_.assign({}, formData, {event_time, extra_time}))
    }

    const handleModalClose = () => {      
        const req = [
            {
                player_id: _.get(formData, ['playerOn', 'id']),
                position_id: _.get(modalData, ['position', 'id'])
            },        
            {
                player_id: _.get(formData, ['playerOff', 'id']),
                time_off: _.get(formData, ['event_time']),
                extra_time: _.get(formData, ['extra_time']),                
                sub_for_id:  _.get(formData, ['playerOn', 'id']),
                position_name: _.get(modalData, ['position', 'name']),
                match_id: match.id
            }
        ]           
        assignPlayers(req) 
        .then(r => {
            updateFormData({})
            updatePlayers([])
            dispatch(closeModal())
        })       
        .catch(error => console.error(error))       
    }

    return (        
        <Modal show={modalOpen} centered>
            <Modal.Header>          
                <Modal.Title>Substitute Player</Modal.Title>
            </Modal.Header>
            <Modal.Body>                        
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Player On</Form.Label>
                                    <Select 
                                        isSearchable
                                        options={_.map(_.filter(players, player => !player.currentPerf), p => { return{value: p, label: p.name} })} 
                                        value={formData.playerOn ? {value: formData.playerOn, label: formData.playerOn.name} : {value: undefined, label: "Choose Player On"}}    
                                        onChange={player => handlePlayerOnSelect(player.value)}
                                        placeholder="Choose Player On"   
                                        className="pi-select sub player-on"  
                                        styles={selectStyles}  
                                        classNamePrefix="select"    
                                    />                                      
                                </Form.Group>
                            </Col>         
                            <Col>
                                <Form.Group>
                                    <Form.Label>Player Off</Form.Label>
                                    <Select 
                                        isSearchable
                                        options={_.map(_.filter(players, player => player.currentPerf), p => { return{value: p, label: p.name} })} 
                                        value={formData.playerOff ? {value: formData.playerOff, label: formData.playerOff.name} : {value: undefined, label: "Choose Player Off"}}    
                                        onChange={player => handlePlayerOffSelect(player.value)}
                                        placeholder="Choose Player Off"   
                                        className="pi-select sub player-off"  
                                        styles={selectStyles}  
                                        classNamePrefix="select"    
                                    />                                      
                                </Form.Group>
                            </Col>         
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Form.Group>
                                    <Form.Label>Minute</Form.Label>                                            
                                        <Form.Range                          
                                            value={_.get(formData, ['event_time']) || 1}
                                            onChange={e => handleTimeUpdate(_.get(e, ['target', 'value']))}
                                            max={_.get(modalData, ['halfPlaying']) === 1 ? _.get(modalData, ['minutesPerHalf']) : ( _.get(modalData, ['minutesPerHalf']) || 0 ) * 2}
                                            min={_.get(modalData, ['halfPlaying']) === 1 ? 1 : ( match.match_time ? 1 : ( _.get(modalData, ['minutesPerHalf']) + 1 ) ) || 1}
                                        />  
                                </Form.Group>
                            </Col>
                            <Col xs={2}>
                                <div className="event-time">{formData.event_time}</div>
                            </Col>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label></Form.Label> 
                                    <Form.Check 
                                        type="checkbox"
                                        id={`xt-check`}
                                        label="Extra time"                               
                                        checked={_.get(formData, ['extra_time']) || false}
                                        onChange={e => updateFormData(_.assign({}, formData, {extra_time: e.target.checked}))}
                                        disabled={(formData.event_time !== (_.get(modalData, ['minutesPerHalf']) + 1)) && formData.event_time < ( _.get(modalData, ['minutesPerHalf']) * (_.get(modalData, ['halfPlaying']) || 2))}
                                    />                    
                                </Form.Group>
                            </Col>  
                        </Row>      
                    </Form> 
                </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => {
                        updateFormData({})
                        dispatch(closeModal())
                        updatePlayers([])
                    }}
                    variant="secondary"
                >
                Cancel</Button>
                <Button onClick={handleModalClose} disabled={_.some(['playerOn', 'playerOff', 'event_time'], key => !_.get(formData, [key]))}>Substitute</Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default SubModal