import { useGetCurrentMatchQuery, useSetCurrentMatchMutation, useGetMatchesQuery } from '../features/matchDay/matchDayAPI'
import _ from 'lodash'
import { Row, Col, Card, Button, Form} from 'react-bootstrap'
import MatchDayComponent from '../features/matchDay/MatchDayComponent'
import CreateMatch from '../features/matchDay/CreateMatch'
import { useDispatch } from 'react-redux'
import selectStyles from '../assets/constants/selectStyles'
import Select from 'react-select'
import { setPlayersPerSide } from '../features/formationPicker/formationPickerSlice'
import { useEffect } from 'react'


const MatchDay = () => {
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])    
    const [ setCurrentMatch ] = useSetCurrentMatchMutation()
    const matches = _.get(useGetMatchesQuery(), ['data', 'matches'])
    const dispatch = useDispatch()
    const otherMatches = _.filter(matches, m => m.id !== _.get(match, ['id']))
   
    useEffect(() => {
        if(match) { 
            dispatch(setPlayersPerSide(match.players_per_side))
        }
    }, [dispatch, match])

    return (
        _.isEmpty(match) ? 
            <>
                {
                    !_.isEmpty(matches) &&
                        <>
                             <div className="match-picker">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formMom">
                                                <Form.Label></Form.Label>
                                                <Select 
                                                    isSearchable
                                                    options={_.map(matches, m => { return {value: m, label: `${m.date} - ${m.opponent}`}})} 
                                                    onChange={selected => setCurrentMatch({match_id: selected.value.id}).catch(er => console.log(er))}
                                                    placeholder="Choose Match"   
                                                    className="pi-select match"  
                                                    styles={selectStyles}  
                                                    classNamePrefix="select"    
                                                />                                      
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                            <div className="divider">OR</div>
                        </>
                }               
                <CreateMatch />
            </>
        :
            <div className="match-day">    
                <Row>                            
                    <Col sm={2}>
                        <Card className="current-match" bg="dark" text="white">
                            <Card.Header>{match.date}</Card.Header>
                            <Card.Body>
                            <Card.Title>{match.opponent}</Card.Title>
                            <Card.Text>                                        
                                <span>{match.match_type}</span>   
                                <span>{"Home"}</span>
                            </Card.Text>
                            <Card.Text>
                                {`${match.players_per_side} a side`}
                            </Card.Text>
                            {
                                match.match_time && 
                                    <Card.Text>{match.difficulty}</Card.Text>                                        
                            }
                            </Card.Body>
                            <Card.Footer>  
                                {
                                    match && otherMatches && !_.isEmpty(otherMatches) &&                                
                                    <Select 
                                        isSearchable
                                        options={_.map(otherMatches, m => { return {value: m, label: `${m.date} - ${m.opponent}`}})} 
                                        onChange={selected => setCurrentMatch({match_id: selected.value.id}).catch(er => console.log(er))}
                                        placeholder="Choose Match"   
                                        className="pi-select match"  
                                        styles={selectStyles}  
                                        classNamePrefix="select"    
                                    />         
                                }
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => setCurrentMatch({match_id: null})}
                                    className="new-match"
                                >
                                    New Match
                                </Button>
                            </Card.Footer>
                        </Card>                     
                    </Col>
                    <Col sm={10}>
                        <MatchDayComponent />                           
                    </Col>
                </Row>               
            </div>   
    )
}

export default MatchDay