import { useSelector } from 'react-redux'
import { NavLink, Outlet } from 'react-router-dom'
import { getCurrentUser } from '../features/auth/authSlice'

const ProtectedRoute = () => {
	const user = useSelector(getCurrentUser)

	// show unauthorized screen if no user is found in redux store
	if (!user) {
		return (
			<div className='unauthorized'>
				<h1>Unauthorized :(</h1>
				<span>
					<NavLink to='/login'>Login</NavLink> to gain access
				</span>
			</div>
		)
	}
	return <Outlet />
}

export default ProtectedRoute