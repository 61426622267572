import {kitVariants, defaultKit} from '../../assets/constants/kitVariants'

const Jersey = props => { 
    const jerseyBackground = "M 397.923,91.92 375.81,82.243 h -65.885 c -8.016,-4.848 -29.356,-16.128 -54.426,-16.128 -25.064,0 -46.41,11.286 -54.433,16.128 H 135.194 L 113.056,91.92 0,205.384 74.431,279.898 129.119,225.216 V 444.87 H 381.884 V 225.261 l 54.65,54.644 74.451,-74.521 z"
    const color1 = props.color1 || defaultKit.color1
    const color2 = props.color2 || defaultKit.color2
    const variant = props.variant || defaultKit.variant

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 510.985 510.985"
            className="jersey"            
        >
            <path fill={color2} d={jerseyBackground} />
            <path fill={color1} d={kitVariants[variant]} />           
        </svg>
    )   
}

export default Jersey