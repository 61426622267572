import { Form, Button, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useState, useEffect } from 'react';
import { useCreateMatchMutation } from './matchDayAPI'
import moment from 'moment'
import _ from 'lodash'
import { useGetKitsQuery } from '../kit/kitAPI'


const CreateMatch = () => {

    const kits = _.get(useGetKitsQuery(), ['data', 'kits'])
	const [ createMatch ] = useCreateMatchMutation()
    const [formData, updateFormData] = useState({       
        players_per_side: 5,
        location: 'Home',
        match_type: 'League',
        matchDate: new Date(),
        match_length: 90,
        opponent: ''
    })

    useEffect(() => {   
        if(!_.isEmpty(kits) && !_.get(formData, ['kit'])) {  
            updateFormData(_.assign({}, formData, {kit: _.first(kits)}))
        }        
    }, [formData, kits])

	const submitForm = () => {	     
        const match = _.omit(_.assign({}, formData, {
            date: moment(formData.matchDate).format("DD/MM/YYYY"),
            kit_id: _.get(formData, ['kit', 'id'])
        }),['matchDate', 'kit'])     
		createMatch({model: match})
		.catch(error => console.error('Error creating match', error))
	}
    

	return (
		<div className="create-match">
            <Form className="create-match-form">
                <Row>     
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>           
                        <Form.Group controlId="create-match">
                            <Form.Label>Opponent</Form.Label>
                            <Form.Control 
                            	type="text" 
                            	name="opponent" 
                            	placeholder="Enter Opponent"
                            	onChange={e => updateFormData(_.assign({}, formData, {opponent: e.target.value}))}
                                value={_.get(formData, ['opponent']) || ''}
                            />  
                        </Form.Group>      
                    </Col>                
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>         
                        <Form.Group>                   
                            <Form.Label>Location</Form.Label>
                            <Form.Control 
                            	as="select" 
                            	name="location"
                            	onChange={e => updateFormData(_.assign({}, formData, {location: e.target.value}))}
                                value={formData.location}
                            >
                                <option>Home</option>
                                <option>Away</option>
                                <option>Neutral</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>    
                        <Form.Group>                   
                            <Form.Label>Type</Form.Label>
                            <Form.Control 
                            	as="select" 
                            	name="match_type"
                            	onChange={e => updateFormData(_.assign({}, formData, {match_type: e.target.value}))}
                                value={_.get(formData, ['match_type'])}
                            >
                                <option>League</option>
                                <option>Cup</option>
                                <option>Friendly</option>                        
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>
                        <Form.Group>                   
                            <Form.Label>Players per side</Form.Label>
                            <Form.Control 
                                as="select" 
                                name="players-ps"
                                onChange={e => updateFormData(_.assign({}, formData, {players_per_side: Number(e.target.value)}))}
                                value={formData.players_per_side}
                            >
                                <option>5</option>
                                <option>7</option>
                                <option>9</option>
                                <option>11</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>
                        <Form.Group>
                            <Form.Label>Date</Form.Label>                   
                            <DatePicker
                                className="pi-datepicker"
                                showIcon
                                selected={formData.matchDate}    
                                onChange={date => updateFormData(_.assign({}, formData, {matchDate: date}))}      
                                dateFormat="dd/MM/yyyy"
                            />               
                        </Form.Group>   
                    </Col>
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>      
                        <Form.Group>
                            <Form.Label>Match Length</Form.Label>                   
                            <Form.Control
                                className="match-length"                     
                                value={formData.match_length}    
                                type="number"                                                                               
                                onChange={e => updateFormData(_.assign({}, formData, {match_length: Number(e.target.value)}))}     
                            />               
                        </Form.Group> 
                    </Col>
                </Row>
                <Row>
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 0}}>
                        {
                            !_.isEmpty(kits) &&                
                                <Form.Group>                   
                                    <Form.Label>Kit</Form.Label>
                                    <Form.Control 
                                        as="select"                        
                                        onChange={e => updateFormData(_.assign({}, formData, {kit: _.find(kits, kit => kit.id === Number(e.target.value))}))}
                                        value={_.get(formData, ['kit', 'id'])}
                                        placeholder="Select Kit"
                                    >                          
                                        {
                                            _.map(kits, kit => (<option key={kit.id} value={kit.id}>{kit.name}</option>))
                                        }
                                    </Form.Control>
                                </Form.Group>      
                        }  
                    </Col>      
                </Row>
                <Row>       
                    <Col xs={{span: 8, offset: 2}} md={{span: 6, offset: 6}}>   
                        <Button 
                            variant="primary" 
                            onClick={() => submitForm()}
                            disabled={!_.get(formData, ['opponent'])}
                        >
                            Create Match
                        </Button>    
                    </Col>
                </Row>               
            </Form>
        </div>
	)
}

export default CreateMatch