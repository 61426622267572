export const enIE = {
	'login':'Login',
	'login.btn':'SIGN IN',
	'login.username': 'Username',
	'login.username.placeholder': 'Enter Username or Email',
	'login.password': 'Password',
	'login.password.placeholder': 'Enter Password',
	'login.register': 'Create new account',
	'login.forgotPassword': 'Forgot password',
	'register': "register",
	'register.username.label': 'Username',
	'register.email.label': 'Email',
	'register.emailConfirm.label': 'Confirm Email',
	'register.password.label': 'Password',
	'register.passwordConfirm.label': 'Confirm Password',
	'register.creatAccount.btn': 'Create Account',
	'nav.link.home': 'Home',
	'nav.link.menu': 'Menu',
	'nav.link.settings': 'Settings',
	'nav.link.contact': 'Contact Us',	
	'nav.btn.logout': 'Logout',
	'footer.copywright': 'Ⓒ 2024 pitchinvader.co.uk',
	'footer.description': 'statistical sports team analysis programmes',
	'forgotPassword.instructions': 'forgot password instructions',
	'forgotPassword.title': 'Forgot password',
	'forgotPassword.send.label': 'Send',
	'home.title': 'Home page',
	'passwordReset.form.title': 'Forgot password',
	'passwordReset.form.instructions': 'Enter your email to recieve an email to reset your password',
	'passwordReset.enterCode.label': 'Enter code',
	'updatePassword.instructions': 'Enter a new password',
	'updatePassword.password.label': 'Set Password'
}