import { apiSlice } from "../../app/apiSlice";

export const teamAPI = apiSlice.injectEndpoints({    
    tagTypes: ["Kits", "Team", "Teams", "Match", "Matches", "Formation", "Formations"],
    endpoints: builder => ({      
        getCurrentTeam: builder.query({
            query: () => 'team/current',
            method: 'GET',
            providesTags: ['Team']        
        }),   
        getUserTeams: builder.query({
            query: () => 
                'user/teams',
                method: 'GET',
                providesTags: ['Teams']       
        }),
        setCurrentTeam: builder.mutation({
            query: teamId => ({
                url: 'team/current',
                method: 'POST',
                body: {team_id: teamId}
            }),
            invalidatesTags: ['Team', "Match", "Matches", "Formation", "Formations"]
        }),       
        addPlayer: builder.mutation({
            query: req => ({
                url: 'player',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['Team', "Teams"]
        }),
        updatePlayer: builder.mutation({
            query: req => {
                const { player, team_id } = req
                return {
                    url: `player/${player.id}`,
                    method: 'PATCH',
                    body: {player, team_id}
                }
            },
            invalidatesTags: ['Team', "Teams"]
        }),
        addTeam: builder.mutation({
            query: req => ({
                url: 'team',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['Team', "Teams"]
        }),
        updateTeam: builder.mutation({
            query: req => {
                const { team } = req 
                return {
                    url: `team/${team.id}`,
                    method: 'PATCH',
                    body: {team}
                }
            },
            invalidatesTags: ['Team', "Teams"]
        }),
        getRatings: builder.query({
            query: args => {
                const { positions } = args
                return {
                    url: 'player/rating',
                    method: 'GET',
                    params: { positions }    
                }
            }         
        })
    })
})

export const  { 
    useGetUserTeamsQuery, 
    useGetCurrentTeamQuery, 
    useSetCurrentTeamMutation,
    useCreateTeamMutation,
    useAddPlayerMutation,
    useAddTeamMutation,
    useGetRatingsQuery,
    useUpdatePlayerMutation,
    useUpdateTeamMutation
} = teamAPI