import { useGetCurrentMatchQuery } from './matchDayAPI'
import _ from 'lodash'
import { Col } from 'react-bootstrap'
import { useGetCurrentTeamQuery } from '../team/teamAPI'
import { useGetMatchEventsQuery } from '../matchDay/matchDayAPI'
import eventTypes from '../../assets/constants/eventTypes'

const TeamsBanner = props => {
	const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
	const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])	
	const matchEvents = _.get(useGetMatchEventsQuery(match.id, {skip: !match}), ['data', 'match_events'])
	const goalType = _.findIndex(eventTypes, et => et.value === 'goal') + 1	
	const goalsScored = _.size(_.filter(matchEvents, me => me.event_type === goalType && me.performance))
    const goalsConceded = _.size(_.filter(matchEvents, me => me.event_type === goalType && !me.performance))
	
	return (
		<div className="teams-banner">					
			<Col>
				<div className="team" onClick={() => props.handleAddMatchEvent(false)}>
					<div className="team-name">
						{_.get(team, ['name'])}
					</div>
					<div className="score">
						{goalsScored}					
					</div>
				</div>
			</Col>
			<Col>
				<div className="seperator">:</div>
			</Col>
			<Col>					
				<div className="team opponent" onClick={() => props.handleAddMatchEvent(undefined, true)}>
					<div className="score">
						{goalsConceded}
					</div>
					<div className="team-name">
						{_.get(match, ['opponent'])}
					</div>
				</div>
			</Col>
		</div>
	)
}

export default TeamsBanner