import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row, Tabs, Tab, ListGroup, Accordion, ButtonGroup, Container, Collapse } from 'react-bootstrap';
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentMatchQuery, useUpdateMatchMutation, useGetMatchEventsQuery } from './matchDayAPI'
import Select from 'react-select'
import selectStyles from '../../assets/constants/selectStyles'
import { useGetCurrentTeamQuery } from '../team/teamAPI'
import eventTypes from '../../assets/constants/eventTypes'
import goalSetups from '../../assets/constants/goalSetups'
import { BiTrash, BiEdit } from "react-icons/bi";
import TimeSelect from './TimeSelect'

const ResultModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('result'))
    const modalData = useSelector(getModalData('result'))
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
    const [ updateMatch ] = useUpdateMatchMutation()
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])
    const [formData, updateFormData] = useState({})
    const difficulties = [{label: 'Easy', value: 'e'}, {label: 'Medium', value: 'm'}, {label: 'Hard', value: 'h'}]
    const goalType = _.get(_.find(eventTypes, et => et.value === 'goal'), ['key'])
    const goalsScored = _.size(_.filter(_.get(formData, ['matchEvents']), me => me.event_type === goalType && !!me.performance))
    const goalsConceded = _.size(_.filter(_.get(formData, ['matchEvents']), me => me.event_type === goalType && !me.performance))
    const matchEvents = _.get(useGetMatchEventsQuery(match.id, {skip: !match}), ['data', 'match_events'])    
    const [activeEventType, setActiveEventType] = useState('scored')
    const [nextTempID, setNextTempID] = useState(-1) 
    const [deletedMEs, updateDeletedMEs] = useState([])
    const [matchEventEditing, setMatchEventEditing] = useState(undefined)
    const [editMEOpen, setEditMEOpen] = useState(false)
    const [lastEventTime, setLastEventTime] = useState(1)

    const handleUpdateMatchEvent = (key, value) => {        
        let matchEvent = _.cloneDeep(matchEventEditing)
        if(!matchEvent){ 
            const createMatchEvent = _.get(tabs, [activeEventType, 'createMatchEvent'])
            if(typeof createMatchEvent === 'function') {
                matchEvent = createMatchEvent(nextTempID)     
                setNextTempID(nextTempID - 1)                
            }
            matchEvent.new = true    
            matchEvent.event_time = lastEventTime || 1        
        }        
        matchEvent.updated = true
        matchEvent[key] = value
        setMatchEventEditing(matchEvent)            
    }

    const handleDeleteMatchEvent = matchEvent => {
        updateFormData(_.assign({}, formData, {matchEvents: _.filter(formData.matchEvents, me => me.id !== matchEvent.id)}))
        if(matchEvent.id > 0) {
            updateDeletedMEs(_.concat(deletedMEs, matchEvent.id))
        }
    }

    const handleAddMatchEvent = () => {          
        const matchEvents = _.get(matchEventEditing, ['new']) ? 
            _.concat(formData.matchEvents,  _.omit(matchEventEditing, ['new'])) :
            _.map(formData.matchEvents, me => me.id === matchEventEditing.id ? matchEventEditing : me)
        updateFormData(_.assign({}, formData, {matchEvents}))  
        setLastEventTime(matchEventEditing.event_time)
        setMatchEventEditing(undefined)     
        setEditMEOpen(false)
    }

    const handleModalCancel = () => {
        if(typeof props.onCancel === 'function') {
           props.onCancel()
        }
        setMatchEventEditing(undefined)
        updateFormData({})
        dispatch(closeModal())
    }

    const handleModalClose = () => {     
        const prepMEReq = me => {            
            let matchEvent = _.omit(me, ['performance', 'subbed_for', 'assisted_by', 'match', 'updated'])
            if(_.get(me, ['performance', 'id'])) {
                matchEvent.performance_id = me.performance.id 
            }else {
                matchEvent.player_id = _.get(me, ['performance', 'player', 'id'])
            }
            if(matchEvent.event_time > match.match_length){
                matchEvent.extra_time = matchEvent.event_time - match.match_length
                matchEvent.event_time = match.match_length
            }
            
            matchEvent.subbed_for_id = _.get(me, ['subbed_for', 'id'])
            matchEvent.assisted_by_id = _.get(me, ['assisted_by', 'id'])
            if(matchEvent.id < 0){
                delete matchEvent.id
            }
            matchEvent.event_type = _.get(_.find(eventTypes, et => et.key === me.event_type), ['value'])
            if(me.goal_setup) {
                matchEvent.goal_setup = _.get(_.find(goalSetups, et => et.key === me.goal_setup), ['value'])
            }
            if(typeof matchEvent.event_type === 'string') {
                matchEvent.event_type = _.get(_.find(eventTypes, et => et.value === matchEvent.event_type, ['key']))
            }
            if(typeof matchEvent.goal_setup === 'string') {
                matchEvent.goal_setup = _.get(_.find(goalSetups, et => et.value === matchEvent.goal_setup, ['key']))
            }
            return matchEvent
        }
        const match_events =  {
            deleted: deletedMEs,
            updated: _.map(_.filter(formData.matchEvents, me => me.updated && me.id > 0), prepMEReq),
            new: _.map(_.filter(formData.matchEvents, me => me.id < 0), prepMEReq)
        }     
    
        const req = { 
            match_id: match.id, 
            match: _.omit(_.assign({}, formData, {
                man_of_match_id: _.get(formData, ['man_of_match', 'value', 'id']),
                coaches_shout_id: _.get(formData, ['coaches_shout', 'value', 'id']),
                match_time: match.match_length,       
                match_events,
                performances: _.map(_.filter(formData.performances, p => !p.id), p => _.omit(p, ['player']))
            }), ['man_of_match', 'matchEvents', 'coaches_shout']),
        }

        updateMatch(req)
        .then(response => {
            if(typeof props.onClose === 'function') {
                props.onClose()
            }
            if(_.get(response, ['data', 'success'])) {
                setMatchEventEditing(undefined)
                updateFormData({})
                dispatch(closeModal())   
            }
        })
        .catch(error => console.error(error))  
    }

    const selectTab = tabKey => {
        setActiveEventType(tabKey)
        setEditMEOpen(false)
        setMatchEventEditing(undefined)
    }

    useEffect(() => {        
        if(modalOpen && _.isEmpty(formData) && modalData && match && team && matchEvents) {  
            const formData = {                
                difficulty: match.difficulty,
                man_of_match: _.find(team.players, p => p.id === match.man_of_match),
                coaches_shout: _.find(team.players, p => p.id === match.coaches_shout),
                matchEvents: _.map(matchEvents, me => _.assign({}, me, {event_time: me.event_time + me.extra_time})),
                performances: _.isEmpty(match.performances) ? 
                    _.map(modalData.performances, perf => _.assign({}, perf, {player: _.find(team.players, p => p.id == perf.player_id)})) :
                    match.performances                   
            }            
            updateFormData(formData)
        }        
    }, [modalOpen, formData, modalData, match, team, matchEvents])      
 
    const PerformanceSelect = props => (
        <Form.Group>
            <Form.Label>{props.label}</Form.Label>
            <Select 
                isSearchable
                options={_.map(_.filter(formData.performances, p => p.player_id !== _.get(matchEventEditing, ['assisted_by', 'id'])), p => { return {value: p, label: p.player.name} })} 
                value={_.get(matchEventEditing, ['performance', 'player', 'name']) ? {value: matchEventEditing.performance, label: matchEventEditing.performance.player.name} : ''}    
                onChange={selected => handleUpdateMatchEvent('performance', selected.value)}
                placeholder="Choose Player"   
                className="pi-select"  
                styles={selectStyles}  
                classNamePrefix="select"    
                required
            />                                      
        </Form.Group>    
    )

    const GoalSetupSelect = () => (
        <Form.Group>
            <Form.Label>Goal Setup</Form.Label>
            <Select                 
                options={goalSetups} 
                value={_.find(goalSetups, gs => gs.key === _.get(matchEventEditing, ['goal_setup'])) || ''}    
                onChange={selected => handleUpdateMatchEvent('goal_setup', selected.key)}
                placeholder="Choose Setup"   
                className="pi-select"  
                styles={selectStyles}  
                classNamePrefix="select"    
                required
            />                                      
        </Form.Group>  
    )
    
const tabs = {
        scored: {
            label: 'Scored',
            filter: matchEvent => _.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['value']) === 'goal' && matchEvent.performance,
            text: matchEvent => `Goal scored by ${_.get(matchEvent, ['performance', 'player', 'name'])}`,
            form: (
                <>
                    <Row>
                        <Col>
                            <PerformanceSelect label="Scored By"/>
                        </Col>

                        <Col xs={6}>
                            <GoalSetupSelect/>
                        </Col>                        
                    </Row>
                    <Row>                        
                        <Col xs={6}>                            
                            <TimeSelect 
                                onChange={e => handleUpdateMatchEvent('event_time', e.target.value)}
                                matchLength={match.match_length}
                                eventTime={_.get(matchEventEditing, ['event_time'])}
                                lastEventTime={lastEventTime}
                            />                                    
                        </Col>        
                        <Col>
                            <Form.Group>
                                <Form.Label>Assisted By</Form.Label>
                                <Select 
                                    isSearchable
                                    options={_.map(_.filter(formData.performances, p => p.player_id !== _.get(matchEventEditing, ['performance', 'player_id'])), p => { return {value: p.player, label: p.player.name} })} 
                                    value={_.get(matchEventEditing, ['assisted_by', 'name']) ? {value: matchEventEditing.assisted_by, label:matchEventEditing.assisted_by.name} : ''}    
                                    onChange={selected => handleUpdateMatchEvent('assisted_by', selected.value)}                                  
                                    placeholder="Choose Player"   
                                    className="pi-select"  
                                    styles={selectStyles}  
                                    classNamePrefix="select"    
                                />                                      
                            </Form.Group>  
                        </Col>
                    </Row>                               
                </>
            ),
            createMatchEvent: id => {
                return { 
                    id, 
                    event_type: _.get(_.find(eventTypes, et => et.value === 'goal'), ['key'])
                }
            },
            addBtnLabel: 'Add Goal Scored',
            isValid: _.get(matchEventEditing, ['performance']) && _.get(matchEventEditing, ['goal_setup']),
            emptyListLabel: 'No Goals Scored'
        },
        conceded: {
            label: 'Conceded',
            filter: matchEvent => _.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['value']) === 'goal' && !matchEvent.performance,
            text: matchEvent => `Goal Conceded`,
            form: (
                <Row>
                    <Col xs={6}>
                        <TimeSelect 
                            onChange={e => handleUpdateMatchEvent('event_time', e.target.value)}
                            matchLength={match.match_length}
                            eventTime={_.get(matchEventEditing, ['event_time'])}
                            lastEventTime={lastEventTime}
                        />     
                    </Col>                  
                    <Col xs={6}>  
                        <GoalSetupSelect/>
                    </Col>  
                </Row>
            ),
            createMatchEvent: id => {
                return { 
                    id, 
                    event_type: _.get(_.find(eventTypes, et => et.value === 'goal'), ['key']),
                }
            },
            addBtnLabel: 'Add Goal Conceded',
            isValid: () => _.get(matchEventEditing, ['event_time']) && _.get(matchEventEditing, ['goal_setup']),
            emptyListLabel: 'No Goals Conceded'
        },
        matchEvents: {
            label: 'Events',
            filter: matchEvent => _.endsWith(_.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['value']), '_card') || 
                _.startsWith(_.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['value']), 'pen_'),
            text: matchEvent => `${_.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['label'])} for ${_.get(matchEvent, ['performance', 'player', 'name'])}`,
            form: (
                <>
                    <Row>
                        <Col xs={6}>  
                            <Form.Group>
                                <Form.Label>Event</Form.Label>
                                <Select                                     
                                    options={_.filter(eventTypes, et => _.endsWith(et.value, '_card') || _.startsWith(et.value, 'pen_'))} 
                                    value={_.find(eventTypes, et => et.key === _.get(matchEventEditing, ['event_type'])) || ''}    
                                    onChange={selected => handleUpdateMatchEvent('event_type', selected.key)}
                                    placeholder="Choose Event"   
                                    className="pi-select"  
                                    styles={selectStyles}  
                                    classNamePrefix="select"    
                                    required
                                />                                      
                            </Form.Group>      
                        </Col>  
                        <Col xs={6}>  
                            <PerformanceSelect label="Player" />
                        </Col>
                    </Row>
                    <Row>                        
                        <Col xs={6}>
                            <TimeSelect 
                                onChange={e => handleUpdateMatchEvent('event_time', e.target.value)}
                                matchLength={match.match_length}
                                eventTime={_.get(matchEventEditing, ['event_time'])}
                                lastEventTime={lastEventTime}
                            />     
                        </Col>   
                    </Row>
                </>
            ),
            createMatchEvent: id => {
                return {id}
            },
            addBtnLabel: 'Add Event',
            isValid: _.get(matchEventEditing, ['performance']) && _.get(matchEventEditing, ['event_type']),
            emptyListLabel: 'No Events'
        },
        subs : { 
            label: 'Subs',
            filter: matchEvent => _.get(_.find(eventTypes, et => et.key === matchEvent.event_type), ['value']) === 'sub',
            text: matchEvent => `${_.get(matchEvent, ['performance', 'player', 'name'])} subbed on for ${_.get(matchEvent, ['subbed_for', 'name'])}`,
            form:  (
                <>
                    <Row>                                               
                        <Col xs={6}>  
                            <PerformanceSelect 
                                matchEvent={matchEventEditing} 
                                label="Player On"
                                options={_.map(_.get(team, ['players']), p => {return {value: p, label: p.name}})}
                            />
                        </Col>                      
                        <Col xs={6}>
                            <Form.Group>
                                <Form.Label>Player Off</Form.Label>
                                <Select 
                                    isSearchable
                                    options={_.map(_.filter(_.get(team, ['players']), pl => _.get(matchEventEditing, ['performance', 'player' , 'id']) !== pl.id && _.find(match.performances, perf => _.get(perf, ['player', 'id']) === pl.id)), p => { return {value: p, label: p.name} })} 
                                    value={_.get(matchEventEditing, ['subbed_for', 'name']) ? {value: matchEventEditing.subbed_for, label:matchEventEditing.subbed_for.name} : undefined}    
                                    onChange={selected => handleUpdateMatchEvent(matchEventEditing, 'subbed_for', selected.value)}
                                    placeholder="Choose Player"   
                                    className="pi-select"  
                                    styles={selectStyles}  
                                    classNamePrefix="select"    
                                    required
                                />                                      
                            </Form.Group>    
                        </Col>      
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <TimeSelect 
                                onChange={e => handleUpdateMatchEvent('event_time', e.target.value)}
                                matchLength={match.match_length}
                                eventTime={_.get(matchEventEditing, ['event_time'])}
                                lastEventTime={lastEventTime}
                            />     
                        </Col>  
                    </Row>
                </>
            ),
            addBtnLabel: 'Add Substitution',
            isValid: () => true,
            emptyListLabel: 'No Substitutions Made'
        }
    }


    return (        
        <Modal show={modalOpen} centered className="match-result-modal">
            <Modal.Header>          
                <Modal.Title>Match Result</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    match && team && !_.isEmpty(formData) &&                
                        <Container>
                            <Row className="score-row">
                                <Col>
                                    {team.name}    
                                </Col>
                                <Col>
                                    {goalsScored}
                                </Col>                     
                                <Col>
                                    {goalsConceded}
                                </Col>
                                <Col>
                                    {match.opponent}    
                                </Col>                         
                            </Row>
                                <Col>
                                    <div className="match-events">
                                        <ButtonGroup className="me-filters">
                                            {
                                                _.keys(tabs).map((tabKey, index) => (
                                                    <Button 
                                                        variant="outline-primary"
                                                        active={activeEventType === tabKey}
                                                        onClick={() => selectTab(tabKey)}
                                                        key={index}
                                                        size="sm"
                                                        disabled={tabKey === 'subs'}
                                                    >
                                                        {tabs[tabKey].label}
                                                    </Button>
                                                ))
                                            }                                            
                                        </ButtonGroup>
                                        <ListGroup>
                                            {
                                                _.isEmpty(_.filter(formData.matchEvents, me => tabs[activeEventType].filter(me))) ?
                                                    <div className="empty-list">{_.get(tabs, [activeEventType, 'emptyListLabel'])}</div>
                                                :
                                                _.map(_.filter(formData.matchEvents, me => tabs[activeEventType].filter(me)), matchEvent => (
                                                    <ListGroup.Item className="match-event" key={matchEvent.id}>
                                                            <div className="event-time">
                                                                {matchEvent.event_time > match.match_length ? `${matchEvent.event_time - match.match_length}xt` : matchEvent.event_time}
                                                            </div>
                                                            <div className="name">
                                                                {_.get(tabs, [activeEventType, 'text'])(matchEvent)}
                                                            </div> 
                                                            <div className="icon-holder">
                                                                <Button 
                                                                    size="sm" 
                                                                    onClick={() => {
                                                                        setMatchEventEditing(matchEvent)
                                                                        setEditMEOpen(true)
                                                                    }}
                                                                    variant="outline-primary"
                                                                >
                                                                    <BiEdit />
                                                                </Button>
                                                                <Button 
                                                                    size="sm" 
                                                                    onClick={() => handleDeleteMatchEvent(matchEvent)}
                                                                    variant="outline-danger"
                                                                >   
                                                                    <BiTrash />
                                                                </Button>     
                                                            </div>
                                                    </ListGroup.Item>
                                                ))
                                            }                                                                    
                                        </ListGroup>                      
                                    </div>
                                    <Button
                                        onClick={() => setEditMEOpen(!editMEOpen)}                                        
                                        aria-expanded={editMEOpen}
                                        size="sm"      
                                        hidden={editMEOpen}
                                        className="add-me-btn"
                                    >
                                        {_.get(tabs, [activeEventType, 'addBtnLabel'])}
                                    </Button>
                                    <Collapse in={editMEOpen}>
                                        <Form>
                                            { _.get(tabs, [activeEventType, 'form']) } 
                                            <Row>
                                                <Col>  
                                                    <div className="btn-holder">                                     
                                                        <Button 
                                                            onClick={() => {
                                                                setMatchEventEditing(undefined)
                                                                setEditMEOpen(false)
                                                            }}     
                                                            size="sm"
                                                            variant="secondary"                                               
                                                        >
                                                                Cancel
                                                        </Button>  
                                                            <Button 
                                                                onClick={() => handleAddMatchEvent()}
                                                                disabled={!_.get(matchEventEditing, ['event_time']) || !tabs[activeEventType].isValid}
                                                                size="sm"
                                                            >
                                                                {_.isEmpty(matchEventEditing) || _.get(matchEventEditing, ['new']) ? 'Add' : 'Update'}
                                                            </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>                  
                                    </Collapse>
                                </Col>                               
                            <Row>                                                                           
                            </Row>
                            <Row>
                                <Form className="match-form">
                                    <Row>                                        
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>Man of Match</Form.Label>
                                                <Select 
                                                    isSearchable
                                                    options={_.concat(_.map(team.players, p => { return {value: p, label: p.name} }), [{value: {id: 0}, label: 'None'}])} 
                                                    value={_.get(formData, ['man_of_match']) ? formData.man_of_match : undefined}    
                                                    onChange={selected => updateFormData(_.assign({}, formData, {"man_of_match": selected}))}
                                                    placeholder="Choose Player"   
                                                    className="pi-select mom"  
                                                    styles={selectStyles}  
                                                    classNamePrefix="select"    
                                                />                                      
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>Coaches Shout</Form.Label>
                                                <Select 
                                                    isSearchable
                                                    options={_.concat(_.map(team.players, p => { return {value: p, label: p.name} }), [{value: {id: 0}, label: 'None'}])} 
                                                    value={_.get(formData, ['coaches_shout']) ? formData.coaches_shout : undefined}    
                                                    onChange={selected => updateFormData(_.assign({}, formData, {"coaches_shout": selected}))}
                                                    placeholder="Choose Player"   
                                                    className="pi-select mom"  
                                                    styles={selectStyles}  
                                                    classNamePrefix="select"    
                                                />                                      
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.Label>Difficulty</Form.Label>
                                                    <Form.Select 
                                                        value = {_.get(_.find(difficulties, d => d.value === _.get(formData, ['difficulty'])), 'label')}
                                                        onChange={e => updateFormData(_.assign({},formData, {difficulty: _.get(_.find(difficulties, d => d.label === _.get(e, ['target', 'value'])), ['value'])}))}
                                                    >
                                                        {
                                                            _.map(difficulties, diff => (<option key={diff.value}>{diff.label}</option>))
                                                        }                                            
                                                    </Form.Select>                                      
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Row>
                        </Container>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Cancel</Button>
                <Button onClick={handleModalClose}>Apply</Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default ResultModal