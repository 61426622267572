import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const matchDaySlice = createSlice({
	name: 'matchDay',
	initialState: {},
	reducers: {
		setIsTimerRunning: (state, action) => {
			state.timerRunning = action.payload
		}
	}
})

export const { 
    setIsTimerRunning
} = matchDaySlice.actions

export const getIsTimerRunning = state => _.get(state.matchDaySlice, ['timerRunning'])
export default matchDaySlice.reducer