import { 
    useGetCurrentFormationQuery,
    useAssignPlayersMutation,
    useGetCurrentMatchQuery,
    useUpdateMatchMutation
} from './matchDayAPI'
import { setIsTimerRunning } from './matchDaySlice'
import _ from 'lodash'
import FormationPicker from '../formationPicker/FormationPicker'
import { Container, Row, Col, ListGroup, Button, Card } from 'react-bootstrap'
import Select from 'react-select'
import {     
    getSelectedJersey, 
    getJerseyHovered,
    toggleSelectedJersey,
    setJerseyHover,
    toggleShowZones
} from '../formationPicker/formationPickerSlice'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { useState, useEffect } from 'react' 
import { useStopwatch } from 'react-timer-hook'
import { openModal } from '../../app/appSlice'
import ResultModal from './ResultModal'
import SubModal from './SubModal'
import MatchEventModal from './MatchEventModal'
import MatchReport from './MatchReport'
import selectStyles from '../../assets/constants/selectStyles'
import FormationSelect from './FormationSelect'
import TeamsBanner from './TeamsBanner'
import { useGetCurrentTeamQuery } from '../team/teamAPI'

const MatchDayComponent = props => {
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
    const formation = _.get(useGetCurrentFormationQuery(_.get(match, ['id']), {skip: !match}), ['data', 'formation'])    
    const dispatch = useDispatch()
    const selectedJersey = useSelector(getSelectedJersey)       
    const jerseyHovered = useSelector(getJerseyHovered)    
    const [ assignPlayers ] = useAssignPlayersMutation()
    const [ updateMatch ] = useUpdateMatchMutation()
    const positionsFilled = !_.some(_.get(formation, ['positions']), p => !p.player_id)
    const minutesPerHalf = match ? match.match_length / 2 : 0
    const [halfPlaying, setHalfPlaying] = useState(0)
    const [firstHalfLength, setFirstHalfLength] = useState(0)

    const {
        totalSeconds,
        seconds,
        minutes,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false }) 

    const openMatchEventModal = (position, opponent=false) => {    
        const currentPerformances = _.filter(match.performances, perf => ( !match.match_time && perf.time_off === match.match_length ) || match.match_time === perf.time_off)
        const currentPlayers = _.map(currentPerformances, cp => _.assign({}, _.find(team.players, p => p.id === cp.player_id), {
            performance: cp,
            performances: _.filter(match.performances, p => p.player_id === cp.player_id)
        }))
        const modalData = {
            matchTime: ( halfPlaying === 1 ? minutes : minutes + minutesPerHalf ) +  1,
            player: position ? _.find(currentPlayers, cp => cp.id === position.player.id) : undefined,          
            minutesPerHalf,
            halfPlaying
        }    
        if(!opponent) {
            if(position) {
                modalData.player = _.find(currentPlayers, cp => cp.id === position.player.id)      
            }            
            modalData.players = currentPlayers
        }else {
            modalData.isOpponent = true
        }  
        dispatch(openModal({modalName: 'matchEvent', modalData}))
    }

    const handlePlayerSelect = (selectedPlayer, position) => { 
        if(match.match_time) {        
            dispatch(openModal({
                modalName: 'sub', 
                modalData: {
                    playerOn: selectedPlayer, 
                    playerOff: position.player, 
                    matchTime: ( halfPlaying === 1 ? minutes : minutes + minutesPerHalf ) +  1,                   
                    position: position,
                    halfPlaying,
                    minutesPerHalf
                }
            }))            
        }else {
            const req = [{
                player_id: selectedPlayer.id,
                position_id: _.get(selectedJersey, ['position_id'])
            }]
            if(halfPlaying > 0) {
                req.push({
                    player_id: position.player.id,
                    time_off: ( minutes >= minutesPerHalf ? minutesPerHalf : firstHalfLength + minutes ) + 1,                
                    sub_for_id: selectedPlayer.id,
                    position_name: position.name,
                    match_id: match.id,
                    extra_time: minutes >=  minutesPerHalf 
                })
            }
            assignPlayers(req)                 
                .catch(error => console.error(error))     
        }
    }

    const handleUnassignPlayer = position => {     
        const req = [{position_id: position.id}]
        assignPlayers(req)
        .catch(error => console.log(error))
    }       

    const endMatch = () => {
        const matchTime = ( firstHalfLength + totalSeconds ) || 5400 
        pause()               
        const performances = _.map(_.filter(formation.positions, pos => pos.player_id), pos => {
            return {
                player_id: pos.player_id,
                position_name: pos.name,                
            }
        })
        dispatch(openModal({modalName: 'result', modalData: {performances, matchTime}}))        
    }

    const endHalf = () => {
        if(halfPlaying > 1) {
            endMatch()
        }
        else {
            setFirstHalfLength(totalSeconds)
            reset(0, false)            
        }
    }

    useEffect(() => {
        if(minutes) {
            if(minutes >= minutesPerHalf + 1) {                
                endHalf()
            }
        }        
    })

    const startHalf = () => {
        const startTimer = () => {
            setHalfPlaying(halfPlaying + 1)        
            start()
            dispatch(setIsTimerRunning(true)) 
        }
        if(halfPlaying === 0 && _.isEmpty(match.performances)) {
            const performances =  _.map(_.filter(formation.positions, pos => pos.player_id), pos => {
                return {
                    player_id: pos.player_id,
                    position_name: pos.name,
                    time_on: 0                
                }
            })
            updateMatch({match_id: match.id, match: {performances}})
            .then(response => startTimer())
            .catch(error => console.error(error))
        }
        else {
            startTimer()
        }
    }

    const stopTimer = () => {
        reset(0, false)          
        dispatch(setIsTimerRunning(false)) 
    }        

    const resumeTimer = () => {
        start()
    }    
   
    return (
        <>
            <Container className="formation-picker">
                <Row>                   
                    <TeamsBanner handleAddMatchEvent={openMatchEventModal}/>                    
                </Row>
                <Row>
                    <Col xs={12} md={6} className="col">
                        <div className="match-timer">
                            <Card>
                                <Card.Body>
                                    <div className="timers">
                                        {
                                            !match.match_time > 0 &&
                                                <>
                                                    <div className="timer">                                                        
                                                        <div className={classnames("time", {overtime: minutes >= minutesPerHalf})}>
                                                            {`${(minutes + '').padStart(2, '0')}:${(seconds + '').padStart(2, '0')}`}
                                                        </div>
                                                    </div>                                                    
                                                </>                                       
                                        }                                
                                    </div>
                                    <div className="status">  
                                        {
                                            match.match_time ? 
                                                <Card.Text>
                                                    Match Ended
                                                </Card.Text>   
                                            :
                                                halfPlaying > 0 ?                                                        
                                                    !isRunning && halfPlaying === 1 && firstHalfLength > 0 ?
                                                        <Card.Text>
                                                            Half Time
                                                        </Card.Text>
                                                    :
                                                        <Card.Text>
                                                            {`${halfPlaying === 1 ? 'First' : 'Second'} half`}
                                                        </Card.Text>
                                                :                                        
                                                    <Card.Text>Match date</Card.Text>
                                        }
                                    </div>                             
                                </Card.Body>
                                <Card.Footer>                              
                                    {
                                        !match.match_time && !isRunning ?
                                            <Button variant="outline-primary" onClick={startHalf} disabled={match.match_time || !positionsFilled || !formation}>
                                                {`Start ${halfPlaying === 1 ? 'Second Half' : 'Match'}`}
                                            </Button>      
                                        :
                                            !match.match_time && halfPlaying < 2 ? 
                                                <Button variant="outline-primary" onClick={endHalf} disabled={match.match_time || !positionsFilled || !formation}>
                                                    End Half
                                                </Button>
                                            :
                                                <></>
                                    }   
                                    <Button variant="outline-primary" onClick={endMatch} disabled={!positionsFilled || !formation}>
                                        {halfPlaying === 0 || match.match_time ? 'Match Result' : 'End Match'}
                                    </Button> 
                                </Card.Footer>
                            </Card>
                        </div>
                        <div className="players">    
                            <ListGroup>
                                {
                                    _.orderBy(_.get(formation, ['positions']), ['y', 'x'], ['asc', 'asc']).map((position, index) => (
                                        <ListGroup.Item 
                                            key={index}
                                            className={classnames({
                                                "hovered": jerseyHovered === position.id,
                                                "selected": _.get(selectedJersey,['position_id']) === position.id
                                            })}
                                            onMouseEnter={() => dispatch(setJerseyHover(position.id))}
                                            onMouseLeave={() => dispatch(setJerseyHover(undefined))}                                        
                                        >
                                            <Select 
                                                isSearchable
                                                options={_.map(team ? _.filter(team.players, pl => !_.find(formation.positions, po => po.player_id === pl.id)) : [], p => { return {value: p, label: p.name} })} 
                                                value={position.player_id ? {value: position.player, label: position.player.name} : {value: undefined, label: "Choose Player"}}    
                                                onChange={selected => handlePlayerSelect(selected.value, position)}
                                                placeholder="Choose Player"     
                                                onMenuOpen={() => { 
                                                    if(_.get(selectedJersey,['position_id']) !== position.id) {
                                                        dispatch(toggleSelectedJersey({index}))       
                                                    }
                                                }}
                                                onMenuClose={() => {
                                                    if(_.get(selectedJersey,['position_id']) === position.id) {   
                                                        dispatch(toggleSelectedJersey({index}))
                                                    }
                                                }} 
                                                className="pi-select player"   
                                                blurInputOnSelect
                                                styles={selectStyles}  
                                                classNamePrefix="select"    
                                            />
                                            <div className={classnames("icon", "position",{assigned: !!position.player_id})}>
                                                {position.name}
                                            </div>   
                                            {
                                                halfPlaying > 0 || match.match_time ?
                                                    <div 
                                                        onClick={() => openMatchEventModal(position)}                                               
                                                        className={classnames("icon","match-event", {disabled: !position.player_id})}
                                                    >
                                                        ME
                                                    </div>
                                                :
                                                    <div 
                                                        onClick={() => handleUnassignPlayer(position)} 
                                                        className={classnames("icon","remove", {disabled: !position.player_id})}
                                                    >
                                                        X
                                                    </div>
                                            }                                                                            
                                        </ListGroup.Item>
                                    ))
                                }
                            </ListGroup>
                        </div>   
                    </Col>      
                    <Col xs={12} md={6} className="col">
                        <FormationSelect />
                        <FormationPicker />
                    </Col>                          
                </Row>
                <Row>
                    <Col xs={3}>
                        <Button onClick={() => dispatch(toggleShowZones())}>Show Zones</Button>
                        {
                            match.match_time && 
                                <ListGroup className="points-list">
                                    {
                                        _.map(match.performances, performance => (
                                            <ListGroup.Item className="points-item"key={performance.id}>
                                                <div className="name">{performance.player.name}</div>
                                                <div className="position">{performance.position_name}</div>
                                                <div className="points">{performance.points}</div>                                                
                                            </ListGroup.Item>                                        
                                        ))
                                    }
                                </ListGroup>                       
                        }                        
                    </Col>
                </Row>         
                <Row>
                    <Col>
                        <MatchReport />
                    </Col>
                </Row>            
            </Container>
            <ResultModal onClose={stopTimer} onCancel={resumeTimer}/>
            <SubModal />   
            <MatchEventModal />  
        </>
    )
}

export default MatchDayComponent