import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './styles/custom.scss'
import Header from './components/Header'
import Footer from './components/Footer'
import Register from './views/Register'
import HomeScreen from './views/Home'
import ProtectedRoute from './routing/ProtectedRoute'
import Contact from './views/Contact'
import LoginModal from './features/auth/LoginModal'
import UpdatePassword from './views/UpdatePassword'
import ForgotPassword from './views/ForgotPassword'
import ResetCode from'./views/ResetCode'
import ScrollToTop from './routing/ScrollToTop'
import About from './views/About'
import Services from './views/Services'
import MatchDay from './views/MatchDay'


function App() {
    return (
        <div id="page-container">
            <Router>
                <ScrollToTop />
                <Header /> 
                <div id="content-wrap">                                   
                    <Routes>
                        <Route path='/' element={<HomeScreen />} />                       
                        <Route path='/contact' element={<Contact />} />                                   
                        <Route path='/register' element={<Register />} /> 
                        <Route path='/services' element={<Services />} /> 
                        <Route path='/about' element={<About />} />                    
                        <Route path='/update-password' element={<UpdatePassword />} />                    
                        <Route path='/forgot-password' element={<ForgotPassword />} />  
                        <Route path='/reset-code' element={<ResetCode />} />                    
                        <Route element={<ProtectedRoute />}>
                            <Route path='/match-day' element={<MatchDay />} />
                        </Route>                          
                    </Routes>
                </div>
                <Footer/>                
            </Router>
            <LoginModal />
        </div>
    )
}
export default App