import { useState, useEffect } from 'react'
import RangeSlider from 'react-bootstrap-range-slider';
import { Form } from 'react-bootstrap'

const TimeSelect = props => {
    const eventTime = props.eventTime || props.lastEventTime || 1        
    const [ value, setValue ] = useState(eventTime); 
    const { matchLength, onChange } = props              

    useEffect(() => setValue(eventTime), [eventTime])

    return (   
        <Form.Group>
            <Form.Label>Time</Form.Label>      
                <RangeSlider
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    min={1}
                    max={matchLength + 5}
                    onAfterChange={onChange}
                    variant="secondary"
                    tooltipLabel={current => value > matchLength ? `${value - matchLength} xt` : value}
                />  
        </Form.Group>             
    )      
}

export default TimeSelect