import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { useState } from 'react'
import _ from 'lodash'
import { useSendMessageMutation } from '../features/contact/contactAPI'
import footballerImg from '../assets/img/footballer.png'

const blankForm = {
    name: '',
    email: '', 
    teamName: '',
    message: ''
}

const Contact = () => {
    const [form, updateForm] = useState(blankForm); 
    const [ sendMessage ] = useSendMessageMutation()

    const submitForm = e => {        
        e.preventDefault()       
        sendMessage(form)
        .then(result => {updateForm(blankForm)})       
        .catch(error => console.error('Update Error', error))
    }


    return (
        <Container fluid className="contact">
            <Row>
                <Col xs={12} className="text-center mt-5">
                    <h1 class="pt-4">Contact Us</h1>
                    <div class="border-top border-primary w-25 mx-auto my-3"></div>
                    <p class="lead">Please enter your details and we will get back to you as soon as possible</p>               
                </Col>                
            </Row>     
            <Row className="jumbo py-5 mb-0">
                <Col md={5} lg={{span:5, offset: 1}} className="my-auto">   
                    <img src={footballerImg} alt="pitchinvader footballer" className="w-100" />
                </Col>   
                <Col md={5} lg={5}>            
                    <div>
                        <Form onSubmit={submitForm}>
                            <div >
                                <Form.Group className="mb-3" controlId="form-name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.name}
                                        onChange={e => updateForm(_.assign({}, form, { name: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        type="email"
                                        value={form.email}
                                        onChange={e => updateForm(_.assign({}, form, { email: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-subject">
                                    <Form.Label>Team Name</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.teamName}
                                        onChange={e => updateForm(_.assign({}, form, { teamName: _.get(e, ['target', 'value']) }))}
                                    />                            
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="form-message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        value={form.message}
                                        onChange={e => updateForm(_.assign({}, form, { message: _.get(e, ['target', 'value']) }))}
                                        as="textarea" rows={4}
                                    />                            
                                </Form.Group>
                            </div>                            
                            <div className="form-actions">
                                <Button 
                                    variant="secondary" 
                                    type="submit"                                     
                                    disabled={
                                        form['name'] === '' || 
                                        form['email'] ==='' || 
                                        form['teamName'] ===''  || 
                                        form['message'] ===''
                                    }
                                >
                                    Send   
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact