import { Container, Row, Col, Button } from 'react-bootstrap'
import aboutUsImg from '../assets/img/about-us.png'

const About = () => (
    <Container fluid className="about px-0">
        <Row>
            <Col xs={12} className="text-center mt-5">
                <h1 className="pt-4">
                    Pitch Invader
                </h1>
                <div class="border-top border-primary w-25 mx-auto my-3"></div>
                <p class="lead">
                    Complete analysis and statistical overhaul for your sports team
                </p>
            </Col>
        </Row>  

        <div className="jumbo">
            <Row>
                <Col xs={{span:5, offset: 1}} className="test-center mt-5">
                    <img src={aboutUsImg} alt="" class="w-100" />                
                </Col>
            </Row>
            <Row className="py-4">
                <Col xs={{span:10 , offset: 1}}>
                    <p class="text-justify">
                        The Pitch Invaders Analysis Systems are the process of assessing performance in a
                        sport to develop an understanding of actions that can inform and support coaches to help them
                        optimize the teams and players performances. Our system consists of tactical game analysis and with our AI learning database modelling programmes we record and update the
                        players’ performances into easy accessible information for Managers/Coaches and sports scientists to delve into and develop.
                    </p>
                    <hr class="my-4" />
                    <p class="text-justify"> 
                        Pitch Invaders is able to provide statistical information to coaching staff about areas of the match day. This detailed information will help locate areas of weakness in their squad, which is in need of improvement.
                        This will give the coaches advice through statistical scrutiny about what systems to use and/or their team selections. 
                    </p>
                    <hr class="my-4" />
                    <p class="text-justify">
                        A research has been conducted and has shown that humans can never recall even up to half the true facts or important actions that happen on a match day, let alone recall them in the future. Emotions run high on match day therefore the more extremely positive or negative events will overshadow the statistical data that is collected during the game. Collecting match information through the Pitch Invader systems will eliminate this completely.
                    </p>
                    <hr class="my-4" />
                    <p class="text-justify">The basis of the Pitch Invader system is to assess the players performances, identifying areas of improvements, then to feedback information to our systems and displaying it in varies way for the coaching staff to easily understand and act upon.
                        The outcome of a good performance analysis means a well-defined coaching plan to improve a team’s or individuals performances. A coach can interpret a report or piece of analysis from Pitch Invaders Analyst engine and make adjustments to the team tactics depending on the statistical outcomes exposed.
                        Our data analysis has been tried and tested over many seasons of amateur football. The more data entered the more accurate the systems will become. Over time our Data Learning Analytic Systems will become astonishingly accurate in predictions.
                    </p>
                    <hr class="my-4" />
                    <Button variant="primary" lg>
                        Sign My Team Up
                    </Button>
                </Col>
            </Row>
            <Row className="py-5 mb-0 text-center">
                <Col md={7} lg={8} xl={9} className="my-auto">
                    Are you interested in getting your team to the next level? 
                </Col>
                <Col md={5} lg={4} xl={3} className="pt-4 pt-md-0">
                    <Button variant="secondary" lg>
                        Contact Us Today
                    </Button>
                </Col>
            </Row>
        </div>
    </Container>
)

export default About