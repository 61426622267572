import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import zones from './constants/zones'

const playerWidth = 60
const playerHeight = 60
const overlap = 5

const initialState = {    
    jerseys: [],      
    counter: 0,
    showZones: false
}

export const formationPickerSlice = createSlice({
    name: 'formationPickerSlice',
    initialState,       
    reducers: {
        setFormation: (state, action) => { 
            const { formation } = action.payload            
            const jerseys = _.map(formation.positions, position => { 
                return {
                    coordinates: { x: position.x, y: position.y }, 
                    position: _.get(_.find(state.positionZones, zone => 
                        position.x > zone.x && position.x <= ( zone.x + zone.width ) && 
                        position.y > zone.y &&  position.y <= ( zone.y + zone.height)
                    ), ['label']),
                    position_id: position.id,
                }
            })
            state.jerseys = jerseys
            state.formationChanged = undefined
        },
        updatePlayer: (state, action) => {
            const { id, data, dragStop } = action.payload
            const x = data.x + ( playerWidth / 2 )
            const y = data.y + ( playerHeight / 2 )
            const playerIndex = _.toNumber(_.replace(id, 'player', ''))        

            if(x !== _.get(state.jerseys, [playerIndex, 'coordinates', 'x']) ||
                    y !== _.get(state.jerseys, [playerIndex, 'coordinates', 'y'])) {
                const otherPlayers = _.concat(
                _.slice(state.jerseys, 0, playerIndex),
                _.slice(state.jerseys, playerIndex + 1)
            )
                const collision = _.some(otherPlayers, player => {     
                    const collisionX = x > (player.coordinates.x - playerWidth + overlap) && x < (player.coordinates.x + playerWidth - overlap)         
                    const colissionY = y > (player.coordinates.y - playerHeight + overlap) && y < (player.coordinates.y + playerHeight - overlap)               
                    return collisionX && colissionY
                })            
                if(collision) {
                    state.jerseys = state.jerseys.map((player, index) => index === playerIndex  ? _.assign({}, player, {collision: !dragStop}) : player)
                }else {                  
                    const position =  _.get(_.find(state.positionZones, zone => 
                        x > zone.x && x <= ( zone.x + zone.width ) && 
                        y > zone.y && y <= ( zone.y + zone.height)
                    ), ['label']) || 'SW'

                    state.jerseys = state.jerseys.map((player, index) => index === playerIndex  ? _.assign({}, player, {coordinates: {x, y}, collision: false, position}) : player)
                    state.formationChanged = true        
                    state.formation = undefined           
                }
            }
        },
        toggleSelectedJersey: (state, action) => {
            const { index } = action.payload
            state.jerseys = state.jerseys.map((j, i) => i === index ? _.assign({}, j, {'selected': !j.selected}) : _.omit(j, ['selected']))
        },
        deselectJersey: state => {
            state.jerseys = _.map(state.jerseys, j => _.omit(j, ['selected']))
        },
        setJerseyHover: (state, action) => {            
            if(!action.payload && action.payload !== 0) {
                state.jerseyHovered = undefined
            }else {
                state.jerseyHovered = action.payload      
            }      
        },
        setPlayersPerSide: (state, action) => {
            const playersPerSide = action.payload
            state.positionZones = _.map(zones, z => _.assign({}, z, {label: _.get(z, ['label', playersPerSide])}))
        },
        toggleShowZones: state => {
            state.showZones = !state.showZones
        }
    }
})

export const { 
    setFormation, 
    updatePlayer, 
    setJerseyTextColor, 
    toggleSelectedJersey, 
    deselectJersey, 
    setJerseyHover,
    setPlayersPerSide,
    toggleShowZones
} = formationPickerSlice.actions

export const getZones = state => _.get(state.formationPickerSlice, ['positionZones'])
export const getJerseyPositions = state => _.get(state.formationPickerSlice, ['jerseys'])
export const getPlayerWidth = () => playerWidth
export const getPlayerHeight = () => playerHeight
export const getOverlap = () => overlap
export const getSelectedJersey = state => _.find(state.formationPickerSlice.jerseys, j => j.selected )
export const getIsFormationChanged = state => !!_.get(state.formationPickerSlice, ['formationChanged'])
export const getJerseyHovered = state => _.get(state.formationPickerSlice, ['jerseyHovered'])
export const getShowZones = state => _.get(state.formationPickerSlice, ['showZones'])
export default formationPickerSlice.reducer