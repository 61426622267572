import { apiSlice } from "../../app/apiSlice";

export const contactAPI = apiSlice.injectEndpoints({       
    endpoints: builder => ({
        sendMessage:  builder.mutation({
            query: req => ({
                url: 'contact',
                method: 'POST',              
                body: req,           
            }),           
        })       
    })
})

export const { useSendMessageMutation } = contactAPI