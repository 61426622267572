import { useSelector } from 'react-redux'
import { getTranslation } from '../app/appSlice'
import logo from '../assets/img/LogoWhiteCutSmall.png'
import { FaFacebook } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa";


const Footer = () => {
    const copywright = useSelector(getTranslation('footer.copywright'))   
    const description = useSelector(getTranslation('footer.description'))   

    return (
        <div id="footer">      
            <img className="logo" src={logo} alt="pitchinvader logo"/>            
            <div className="description">
                {description}
            </div>
            <div className="social-icons">
                <FaFacebook />
                <FaTwitter /> 
                <FaInstagram />
                <FaYoutube />
            </div>
            <div className="copyright">
                {copywright}
            </div>     
        </div>
    )
}

export default Footer