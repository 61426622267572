import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {         
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username') ? {
            username: localStorage.getItem('username'),
            id: localStorage.getItem('user_id'),
        } : undefined
    },
    reducers: {
        setCredentials: (state, action) => {            
            const { id, username, token } = action.payload                  
            state.user = { id, username }
            state.token = token
            localStorage.setItem('username', username)
            localStorage.setItem('token', token)
            localStorage.setItem('user_id', id)
            delete state.tokenRefreshing
        },
        setToken: (state, action) => {  
            const token = action.payload                 
            state.token = token            
            localStorage.setItem('token', token)          
            delete state.tokenRefreshing
        },
        unsetCredentials: state => {
            state.user = null
            state.token = null
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('user_id')
        },        
        setResetCode: (state, action) => { state.resetCode = action.payload },
        setTokenRefreshing: (state, action) => { state.tokenRefreshing = action.payload }
    },
})

export const { 
    setCredentials,
    setToken, 
    unsetCredentials, 
    logOut,      
    setResetCode,
    setTokenRefreshing,
} = authSlice.actions
export default authSlice.reducer
export const getCurrentUser = state => state.authSlice.user
export const getCurrentToken = state => state.authSlice.token
export const getResetCode = state => state.authSlice.resetCode || ''
export const isTokenRefreshing = state => !!state.tokenRefreshing
