import { apiSlice } from "../../app/apiSlice";

export const kitAPI = apiSlice.injectEndpoints({    
    tagTypes: ["Kits"],
    endpoints: builder => ({      
        addKit: builder.mutation({
            query: req => ({
                url: 'kit',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['Kits']
        }),
        updateKit: builder.mutation({
            query: req => {
                const { kit } = req
                return {
                    url: `kit/${kit.id}`,
                    method: 'PATCH',
                    body: {kit}
                }
            },
            invalidatesTags: ['Kits']
        }),
        getKits: builder.query({
            query: () => 
                'kit',
                method: 'GET',
                providesTags: ['Kits']       
        })
    })
})

export const  { 
    useAddKitMutation, 
    useUpdateKitMutation,
    useGetKitsQuery
} = kitAPI