import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAddTeamMutation, useUpdateTeamMutation } from '../team/teamAPI'


const EditTeamModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('editTeam'))  
    const [formData, updateFormData] = useState({})
    const [addTeam] = useAddTeamMutation()
    const [updateTeam] = useUpdateTeamMutation()
    const modalData = useSelector(getModalData('editTeam')) 
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({})

    useEffect(() => {
        if(_.isEmpty(formData) && modalData) {
            const team = _.get(modalData, ['team'])
            updateFormData(_.pick(team, ['name']))
        }        
    }, [formData, modalData])

    const handleAddTeam = () => {            
        const handleResponse = response => {
            if(_.get(response, ['data', 'success'])) {
                    updateFormData({})
                    setError({})
                    dispatch(closeModal())   
            }else if(_.get(response, ['error', 'status']) === 409) {                
                setError(_.assign({}, error, {name: 'Team name exists'}))
            }      
            setValidated(false)    
        }

        const team = _.get(modalData,['team'])
        if(team) {
            updateTeam({team: _.assign({}, team, formData)})
            .then(handleResponse)
        }else {
            addTeam({team: {name: formData.name}})
            .then(handleResponse)
        }        
    }

    const handleModalCancel = () => {
        updateFormData({})
        setError({})
        dispatch(closeModal())
    }

    const handleFormChange = (key, value) => {
        setValidated(false)
        setError(_.omit(error, [key]))
        let update = {}
        update[key] = value
        updateFormData(_.assign({}, formData, update))                                   
    }

    const handleSubmit = e => {      
        e.preventDefault()  
        e.stopPropagation()
        const form = e.currentTarget       
        if(form.checkValidity() && _.isEmpty(error)) {
            handleAddTeam()
        }
        setValidated(true)
    }

    return (        
        <Modal 
            show={modalOpen} 
            centered
            size="sm"
        >
            <Modal.Header>          
                <Modal.Title>{(_.get(modalData, ['team']) ? 'Edit' : 'Add') + ' Team'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form 
                    noValidate 
                    validated={validated} 
                    id="edit-team-form"
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Team Name</Form.Label>
                                <Form.Control                                         
                                    value={_.get(formData, ['name']) || ''} 
                                    onChange={e => handleFormChange('name', _.get(e, ['target', 'value']))}                                    
                                    required        
                                    name="name"   
                                    isInvalid={_.get(error, ['name'])}
                                />  
                                <Form.Control.Feedback type="invalid">
                                    {_.get(error, ['name']) || 'Please enter team name'}
                                </Form.Control.Feedback>                    
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Cancel</Button>
                <Button 
                    type="submit"
                    form="edit-team-form"
                >
                    {_.get(modalData, ['team']) ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default EditTeamModal