import { useSelector } from 'react-redux'
import { getTranslation } from '../app/appSlice'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import Error from '../components/Error'
import { useResetPasswordMutation } from '../features/auth/authAPI'
import { useNavigate } from 'react-router-dom'


const ForgotPassword = () => {     
    const emailLabel = useSelector(getTranslation('register.email.label'))    
    const navigate = useNavigate()
    const [customError, setCustomError] = useState(null)
    const [formEmail, updateFormEmail] = useState('');     
    const [ resetPassword, data ] = useResetPasswordMutation(); 
    const forgotPasswordTitle = useSelector(getTranslation('forgotPassword.title'))
    const instructions = useSelector(getTranslation('forgotPassword.instructions.text'))
    const sendLabel = useSelector(getTranslation('forgotPassword.send.label'))

    const submitForm = e => {    
    	e.preventDefault()  
        resetPassword(formEmail)
    }

    useEffect(() => {        
        if (_.get(data, ['isSuccess'])) {           
            navigate('/reset-code')
        }
        else if (_.get(data, ['isError'])) {
            if(_.get(data, ['error', 'status']) === 400) {
                setCustomError('Email does not exists')
            }
        }
    }, [data, navigate])


    return (
        <Container>
            <Row>
                <Col lg={{ span: 10, offset: 1 }}>  
                        {forgotPasswordTitle}                             
                        {instructions}                             
                    <Form onSubmit={submitForm}>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>{emailLabel + ' :'}</Form.Label>
                            <Form.Control 
                                type="email" 
                                size="lg"
                                value={formEmail}
                                onChange={e => updateFormEmail(_.get(e, ['target', 'value']))}
                            />                            
                        </Form.Group>
                        <div className="form-error">
                            {customError && <Error>{customError}</Error>}
                        </div>
                                              
                        <div className="form-actions">
                            <Button variant="secondary" type="submit" size="lg">
                                {sendLabel}
                            </Button>
                        </div>
                    </Form>     
                </Col>                
            </Row>
        </Container>
    )
}

export default ForgotPassword