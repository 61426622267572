import { 
	useGetCurrentMatchQuery,
    useGetFormationsQuery, 
    useSetCurrentFormationMutation,
    useGetCurrentFormationQuery,
    useUpdateFormationMutation
} from './matchDayAPI'
import _ from 'lodash'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { setFormation } from '../formationPicker/formationPickerSlice'
import { useDispatch } from 'react-redux'
import selectStyles from '../../assets/constants/selectStyles'
import { useEffect } from 'react' 
import { useGetCurrentTeamQuery } from '../team/teamAPI'


const FormationSelect = () => {
	const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
	const formation = _.get(useGetCurrentFormationQuery(_.get(match, ['id']), {skip: !match}), ['data', 'formation'])    
	const dispatch = useDispatch()
	const formations = _.get(useGetFormationsQuery(
            {
            	team_id: _.get(team, ['id']), 
            	num_players: _.get(match, ['players_per_side']), 
            	"match_id": match.id
            },
            {
            	skip: !match || !team
            }
        ), ['data', 'formations'])
	const formationOptions = _.map(formations, f => { return {value: f, label: f.name || "Custom formation"} })
	const [ setCurrentFormation ] = useSetCurrentFormationMutation()
	const [ updateFormation ] = useUpdateFormationMutation()

	const handleCreateFormation = formationName => {    
        updateFormation({
            formation: {
                name: formationName,
                id: formation.id
            }
        })
        .catch(error => console.log(error))
    }

    const handleSelectFormation = selectedFormation => {
        setCurrentFormation({match_id: match.id, formation_id: selectedFormation.id})
        .catch(error => console.error(error))
    }

	useEffect(() => {
        if(formation) { 
            dispatch(setFormation({formation}))
        }
    }, [formation, dispatch])

	return (
		<div className="select-holder">
        {
            !formation || _.get(formation, ['name']) ?
                <Select 
                    isSearchable
                    options={formationOptions} 
                    value={formation ? {value: formation, label: formation.name} : undefined}    
                    onChange={selected => handleSelectFormation(selected.value)}
                    placeholder="Choose formation"
                    className="pi-select formation"
                    styles={selectStyles}
                    classNamePrefix="select"
                />
            :
                <CreatableSelect 
                    isSearchable
                    options={formationOptions} 
                    placeholder="Custom formation"  
                    onCreateOption={formation => handleCreateFormation(formation)} 
                    onChange={selected => handleSelectFormation(selected.value)}     
                    className="pi-select formation"
                    styles={selectStyles} 
                    classNamePrefix="select"     
                    formatCreateLabel={input => `Save as ${input}`} 
                />
        }      
    </div>
	)
}

export default FormationSelect