const selectStyles = {
        control: styles => ({ ...styles, backgroundColor: '#343a40', color: '#f99c00'}),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {  
            return {
                ...styles, 
                'backgroundColor': '#343a40;',          
                ':hover' : { 
                    backgroundColor: '#f99c00'
                },                
            };
        }        
    }
export default selectStyles