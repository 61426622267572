import Draggable from 'react-draggable';
import pitchImg from '../../assets/img/pitch.png'
import Jersey from '../kit/Jersey'
import _ from 'lodash'
import classnames from 'classnames'
import { 
	getZones, 
	getJerseyPositions, 
	updatePlayer,
	getPlayerWidth,
	getPlayerHeight,
	toggleSelectedJersey,
	setJerseyHover,
	getJerseyHovered,
	getShowZones
} from './formationPickerSlice'
import { useSelector, useDispatch } from 'react-redux'
import { 	 
	useUpdatePositionMutation, 
	useGetCurrentMatchQuery, 
	useGetCurrentFormationQuery,
 } from '../matchDay/matchDayAPI'
import { defaultKit } from '../../assets/constants/kitVariants'


const FormationPicker = props => {
	const dispatch = useDispatch()	
	const zones = useSelector(getZones)
	const jerseys = useSelector(getJerseyPositions)
	const playerWidth = useSelector(getPlayerWidth)
	const playerHeight = useSelector(getPlayerHeight)
    const [ updatePosition ] = useUpdatePositionMutation()	
	const jerseyHovered = useSelector(getJerseyHovered)
	const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])
	const formation = _.get(useGetCurrentFormationQuery(_.get(match, ['id']), {skip: !match}), ['data', 'formation'])
	const showZones = useSelector(getShowZones)

	const handleDragStop = (data, jersey) => {
		const { x, y } = _.find(_.get(formation, ['positions']), p => p.id === jersey.position_id)
		if(_.get(jersey, ['coordinates', 'x']) === x && _.get(jersey, ['coordinates', 'y']) === y) {						
			return
		}
		
		const req = {
			position: {
				player_id: jersey.playerId,
				x: jersey.coordinates.x,
				y: jersey.coordinates.y,
				name: jersey.position,
				id: jersey.position_id,
			},
			match_id: match.id,
			team_id: match.team_id
		}		
		updatePosition(req)
		.then(result => dispatch(updatePlayer({id: _.get(data, ['node', 'id']), data: _.pick(data, ['x', 'y']), dragStop: true})))
		.catch(error => console.error(error))
	}

    return (    	
		<div className="pitch">
			{
				showZones && zones &&
					zones.map((zone, index) => (
						<div 
							className="zone" 
							key={zone.label + index}
							style={{
								position: 'absolute',
								width: zone.width,
								height: zone.height,
								top: zone.y,
								left: zone.x,
								border: "1px solid black",
								zIndex: 1								
							}}							
						>
							{zone.label}
						</div>
					))
			}
			<img src={pitchImg} alt="pitch"/>
			{
				jerseys.map((jersey, index) => (
					<Draggable
				        axis="both"
				        bounds="parent"
				        handle=".player"
				        position={{x: jersey.coordinates.x - (playerWidth / 2), y: jersey.coordinates.y - (playerHeight / 2)}}
				    	onDrag={(e, data) => dispatch(updatePlayer({id: _.get(data, ['node', 'id']), data: _.pick(data, ['x', 'y'])}))}
				        onStop={(e, data) => handleDragStop(data, jersey)}
				        key={index}		
				        onMouseDown={e => dispatch(toggleSelectedJersey({index}))}							                
					>
				        <div 
				        	className={classnames("player", {
				        		collision: jersey.collision, 
				        		selected: jersey.selected, 
				        		unassigned: !_.get(_.find(_.get(formation, ['positions']), pos => pos.id === jersey.position_id), ['player']),
				        		hovered: jerseyHovered === jersey.position_id
				        	}, jersey.position)}
				        	id={"player" + index}
				        	style={{width: playerWidth, height: playerHeight}}
				        	onMouseEnter={() => dispatch(setJerseyHover(jersey.position_id))}
				        	onMouseLeave={() => dispatch(setJerseyHover(undefined))}
				        >
							<Jersey 			        	
					        	color1={_.get(match, ['kit', 'color1'])}
					        	color2={_.get(match, ['kit', 'color2'])}
					        	variant={_.get(match, ['kit', 'variant'])}
					        	width={playerWidth}
					        	height={playerHeight}
					        />
					        <div className={classnames("jersey-label")} style={{color: _.get(match, ['kit', 'text-color']) || defaultKit.text_color}}>
					        	{jersey.position}
					        </div>				
				        </div>
			    	</Draggable> 
				))
			}
        </div>
    )
}

export default FormationPicker