import { useSelector, useDispatch } from 'react-redux'
import { Container, Form, Row, Col, Button  } from 'react-bootstrap'
import { getTranslation } from '../app/appSlice'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getResetCode, setResetCode } from '../features/auth/authSlice'


const ResetCode = () => {   
    const resetCode = useSelector(getResetCode)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const formInstructions = useSelector(getTranslation('passwordReset.form.instructions'))
    const formTitle = useSelector(getTranslation('passwordReset.form.title'))
    const enterCodeLabel = useSelector(getTranslation('passwordReset.enterCode.label'))

    const submitForm = e => {    
    	e.preventDefault()         
        navigate('/update-password')
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Form onSubmit={submitForm}>
                        <div>
                            <div>   
                                {formTitle}
                            </div>   
                            {formInstructions} 
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Code:</Form.Label>
                                <Form.Control 
                                    size="lg"
                                    value={resetCode}
                                    onChange={e => dispatch(setResetCode(_.get(e, ['target', 'value'])))}
                                />                            
                            </Form.Group>                            
                        </div>                            
                        <div>
                            <Button variant="secondary" type="submit" size="lg">
                                {enterCodeLabel}
                            </Button>
                        </div>
                    </Form>     
                </Col>                
            </Row>
        </Container>
    )
}

export default ResetCode