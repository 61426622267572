import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Modal, Form, Col, Row, Collapse } from 'react-bootstrap';
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentMatchQuery,  useAddMatchEventMutation } from './matchDayAPI'
import Select from 'react-select'
import selectStyles from '../../assets/constants/selectStyles'
import eventTypes from '../../assets/constants/eventTypes'
import goalSetups from '../../assets/constants/goalSetups'
import TimeSelect from './TimeSelect'

const MatchEventModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('matchEvent'))
    const modalData = useSelector(getModalData('matchEvent'))
    const match = _.get(useGetCurrentMatchQuery(), ['data', 'match'])    
    const [ addMatchEvent ] = useAddMatchEventMutation()
    const [formData, updateFormData] = useState({})
    const [lastEventTime, setLastEventTime] = useState(1)
   
    const handleModalCancel = () => {
        updateFormData({})
        dispatch(closeModal())
    }

    const handleTimeUpdate = minutes => {          
        updateFormData(_.assign({}, formData, {
                event_time: Number(minutes),
                extra_time: formData.extra_time ? false : formData.extra_time
            }
        ))           
    }

    const handleAddMatchEvent = () => {     
        if(!modalData.isOpponent && !formData.player){
            return
        }
        
        const req = {
            performance_id: _.get(formData, ['player', 'performance', 'id']),
            match_event: {
                event_type: formData.eventType.value,
                assisted_by_id: formData.eventType.value === 'goal' && _.get(formData, ['assistingPlayer', 'id']) ? formData.assistingPlayer.id : undefined,
                event_time: formData.event_time > match.match_length ? match.match_length : formData.event_time,
                extra_time: formData.event_time > match.match_length ? formData.event_time - match.match_length: undefined,
                goal_setup: formData.eventType.value === 'goal' ? formData.goalSetup.value : undefined,
                extra_time: formData.extra_time,
                match_id: match.id
            }
        }             
        addMatchEvent(req)
        .then(r => {
            setLastEventTime(formData.event_time)
            updateFormData({})
            dispatch(closeModal())
        })
        .catch(er => console.error(er))
    }

    useEffect(() => {
        if(modalOpen && modalData && _.isEmpty(formData) && match) {     
            const formData = {
                player: modalData.player,                
                event_time: modalData.matchTime + modalData.extra_time,
                assistingPlayer: null,
                eventType: _.find(eventTypes, et => et.value === 'goal'),
                goalSetup: _.find(goalSetups, gs => gs.value === 'open_play')
            }
            updateFormData(formData)
        }
    }, [formData, modalData, match, modalOpen])

    return (        
        <Modal show={modalOpen} centered className="match-event-modal">
            <Modal.Header>          
                <Modal.Title>Match Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Event Type</Form.Label>
                                <Select 
                                    isSearchable
                                    options={_.filter(eventTypes, et => et.value !== 'sub')} 
                                    value={formData.eventType}    
                                    onChange={eventType => updateFormData(_.assign({}, formData, {eventType}))}
                                    className="pi-select match-event event-type"  
                                    styles={selectStyles}  
                                    classNamePrefix="select"    
                                />                                      
                            </Form.Group>
                        </Col>  
                        <Col>
                            {
                                _.get(modalData, ['isOpponent']) ?    
                                    <Form.Group>
                                        <Form.Label>Scored From</Form.Label>
                                        <Select 
                                            isSearchable
                                            options={goalSetups} 
                                            value={formData.goalSetup}    
                                            onChange={goalSetup => updateFormData(_.assign({}, formData, {goalSetup}))}
                                            className="pi-select match-event goal-setup"  
                                            styles={selectStyles}  
                                            classNamePrefix="select"    
                                        />                                      
                                    </Form.Group>   
                                :                         
                                    <Form.Group>
                                        <Form.Label>Player</Form.Label>
                                        <Select 
                                            isSearchable
                                            options={_.map(_.get(modalData, ['players']), p => { return{value: p, label: p.name} })} 
                                            value={formData.player ? {value: formData.player, label: formData.player.name} : {value: undefined, label: "Choose Player"}}    
                                            onChange={player => updateFormData(_.assign({}, formData, {player: player.value}))}
                                            placeholder="Choose Player"   
                                            className="pi-select match-event player"  
                                            styles={selectStyles}  
                                            classNamePrefix="select"    
                                        />                                      
                                    </Form.Group>   
                            }                      
                        </Col>                        
                    </Row>
                        {
                            !_.get(modalData, ['isOpponent']) &&                             
                                <Collapse in={_.get(formData, ['eventType', 'value']) === 'goal'}>                    
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Assisted By</Form.Label>
                                                <Select 
                                                    isSearchable
                                                    options={_.concat(_.map(_.filter(_.get(modalData, ['players']), p => p.id !== _.get(formData, ['player', 'id'])), p => { return {value: p, label: p.name} }), [{value: undefined, label: 'None'}])} 
                                                    value={formData.assistingPlayer ? {value: formData.assistingPlayer, label: formData.assistingPlayer.name} : {value: undefined, label: "Choose Assisting Player"}}    
                                                    onChange={player => updateFormData(_.assign({}, formData, {assistingPlayer: player.value}))}
                                                    placeholder="Choose Assisting Player"   
                                                    className="pi-select match-event player"  
                                                    styles={selectStyles}  
                                                    classNamePrefix="select"    
                                                />                                      
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Scored From</Form.Label>
                                                <Select 
                                                    isSearchable
                                                    options={goalSetups} 
                                                    value={formData.goalSetup}    
                                                    onChange={goalSetup => updateFormData(_.assign({}, formData, {goalSetup}))}
                                                    className="pi-select match-event goal-setup"  
                                                    styles={selectStyles}  
                                                    classNamePrefix="select"    
                                                />                                      
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Collapse>
                        }                    
                    <Row>
                        <Col xs={12}>
                            <TimeSelect 
                                onChange={e => handleTimeUpdate(_.get(e, ['target', 'value']))}
                                matchLength={match.match_length}
                                eventTime={_.get(formData, ['event_time'])}
                                lastEventTime={lastEventTime}
                            />                                
                        </Col>                       
                    </Row>       
                </Form>    
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Cancel</Button>
                <Button onClick={handleAddMatchEvent}>Apply</Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default MatchEventModal