import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { isModalOpen, closeModal } from '../../app/appSlice'
import _ from 'lodash'
import { useState } from 'react'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authAPI'    


function LoginModal() {

	const modalOpen = useSelector(isModalOpen('login'))
	const dispatch = useDispatch()
	const [login, isLoading] = useLoginMutation()	
	const [formData, updateFormData] = useState({})    
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({})

	const handleSubmit = e => {      
        e.preventDefault()
        e.stopPropagation()
        const form = e.currentTarget       
        if(form.checkValidity() && _.isEmpty(error)) {
        	login(formData)
        	.then(response => {
        		const userData = _.get(response, ['data'])
        		if(userData) {        			
        			dispatch(setCredentials(userData))
                    updateFormData({})
                    setError({})
                    dispatch(closeModal())   
	            }else if(_.get(response, ['error', 'status']) === 401 && _.get(response, ['error', 'data', 'error']) === 'Unauthorized') {   
	                setError(_.assign({}, error, {login: 'Login failed'}))
	            }      
            	setValidated(false)
        	})       
        }
        setValidated(true)
    }

	const handleModalCancel = () => {
        updateFormData({})
        setError({})
        dispatch(closeModal())
    }

    const handleFormChange = (key, value) => {
        setValidated(false)
        setError({})
        let update = {}
        update[key] = value
        updateFormData(_.assign({}, formData, update))                                   
    }

	return (
		<Modal 			
			show={modalOpen} 
			onHide={() => dispatch(closeModal())}
			centered	
			size="sm"				
		>
			<Modal.Header>			
				<Modal.Title>Login</Modal.Title>
			</Modal.Header>
			<Modal.Body>				
				<Form 
					noValidate 
                    validated={validated} 
                    id="login-form"
                    onSubmit={handleSubmit}
                 >
					<Row>
						<Col>
							<Form.Group>
								<Form.Label>Email</Form.Label>
								<Form.Control 
									type="email" 
									placeholder="Please enter your email address" 
									value={_.get(formData, ['email']) || ''} 
									onChange={e => handleFormChange('email',  _.get(e, ['target', 'value']))}
									isInvalid={_.get(error, ['login'])}
									required								
								/>						
							</Form.Group>						
							<Form.Group>
								<Form.Label>Password</Form.Label>
								<Form.Control 
									type="password" 
									placeholder={"Please enter your password"} 
									value={_.get(formData, ['password']) || ''} 
									onChange={e => handleFormChange('password',  _.get(e, ['target', 'value']))}
									isInvalid={_.get(error, ['login'])}
									required
								/>
								<Form.Control.Feedback type="invalid">
	                                    {_.get(error, ['login']) || 'Login error'}
	                            </Form.Control.Feedback>     
							</Form.Group>	
						</Col>
					</Row>					
					<Row>
						<a href="/register">Register</a>
					</Row>
					{/*<Row>
						<a href="/forgot-password">{forgotPasswordLabel}</a>	
					</Row>		*/}			
				</Form>	
				
			</Modal.Body>
			<Modal.Footer>		
				<Button onClick={handleModalCancel}>Cancel</Button>		
				<Button variant="secondary" onClick={handleSubmit}>
					Login
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default LoginModal