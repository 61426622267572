import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentTeamQuery } from './teamAPI'
import { useAddKitMutation, useUpdateKitMutation } from '../kit/kitAPI'
import { defaultKit, kitVariants } from '../../assets/constants/kitVariants'
import Jersey from '../kit/Jersey'


const EditKitModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('editKit'))  
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])    
    const [addKit] = useAddKitMutation()
    const [updateKit] = useUpdateKitMutation()
    const modalData = useSelector(getModalData('editKit'))  
    const [formData, updateFormData] = useState({})   
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({}) 

    useEffect(() => {   
        if(modalOpen && _.isEmpty(formData)) {  
            updateFormData(_.get(modalData, ['kit']) ? modalData.kit : defaultKit)
        }        
    }, [formData, modalData, modalOpen])

    const handleAddKit = () => {    
        const handleResponse = response => {
            if(_.get(response, ['data', 'success'])) {
                    updateFormData({})
                    setError({})
                    dispatch(closeModal())   
            }else if(_.get(response, ['error', 'status']) === 409) {                
                setError(_.assign({}, error, {name: 'Kit name exists'}))
            }
            setValidated(false)    
        }     

        const kit = _.get(modalData, ['kit'])        
        if(kit) {
            updateKit({kit: _.assign({}, kit, formData)})
            .then(handleResponse)                
        }else {
            addKit({team_id: team.id, kit: formData})
            .then(handleResponse)              
        }
    }

    const handleModalCancel = () => {
        updateFormData({})
        setError({})
        dispatch(closeModal())
    }

    const handleFormChange = (key, value) => {
        setValidated(false)
        setError(_.omit(error, [key]))
        let update = {}
        update[key] = value
        updateFormData(_.assign({}, formData, update))                                   
    }

    const handleSubmit = e => {      
        e.preventDefault()  
        e.stopPropagation()
        const form = e.currentTarget       
        if(form.checkValidity() && _.isEmpty(error)) {
            handleAddKit()
        }
        setValidated(true)
    }

    return (       
        <Modal 
            show={modalOpen} 
            centered 
            className="edit-kit-modal"            
        >
            <Modal.Header>          
                <Modal.Title>{(_.get(modalData, ['kit']) ? 'Edit' : 'Add') + ' Kit'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form 
                    noValidate 
                    validated={validated} 
                    id="edit-kit-form"
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col className="preview-col">
                            <div className="preview">
                                <Jersey 
                                    color1={_.get(formData, ['color1'])}
                                    color2={_.get(formData, ['color2'])}
                                    variant={_.get(formData, ['variant'])}
                                />
                                <div className="jersey-label" style={{color: _.get(formData, ['text_color'])}}>
                                    {'CF'}
                                </div>      
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control                                         
                                    value={_.get(formData, ['name']) || ''} 
                                    onChange={e => handleFormChange('name',  _.get(e, ['target', 'value']))}
                                    required        
                                    name="name"   
                                    isInvalid={_.get(error, ['name'])}                          
                                />      
                                <Form.Control.Feedback type="invalid">
                                    {_.get(error, ['name']) || 'Please enter kit name.'}
                                </Form.Control.Feedback>                
                            </Form.Group>
                        </Col>                   
                        <Col xs={12} sm={6}>
                            <Form.Group>
                                <Form.Label>Pattern</Form.Label>                                
                                <Form.Select 
                                    onChange={e => updateFormData(_.assign({}, formData, {variant: _.get(e, ['target', 'value'])}))}
                                >                                
                                    {
                                        _.map(_.keys(kitVariants), kitVariantKey => (
                                            <option 
                                                key={kitVariantKey} 
                                                value={kitVariantKey}
                                            >
                                                {kitVariantKey}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Col>                    
                        <Col xs={6} sm={4} className="centered-content">
                            <Form.Group className="color-group">
                                <Form.Label>Primary</Form.Label>
                                    <Form.Control   
                                        type="color"                                      
                                        value={_.get(formData, ['color1']) || ''} 
                                        onChange={e => updateFormData(_.assign({}, formData, {color1: _.get(e, ['target', 'value'])}))}
                                    />                      
                            </Form.Group>
                        </Col>                    
                        <Col xs={6} sm={4} className="centered-content">
                            <Form.Group className="color-group">
                                <Form.Label>Secondary</Form.Label>
                                    <Form.Control   
                                        type="color"                                      
                                        value={_.get(formData, ['color2']) || ''} 
                                        onChange={e => updateFormData(_.assign({}, formData, {color2: _.get(e, ['target', 'value'])}))}
                                    />                      
                            </Form.Group>
                        </Col>                    
                        <Col xs={6} sm={4} className="centered-content">
                            <Form.Group className="color-group">
                                <Form.Label>Position Label</Form.Label>
                                    <Form.Control  
                                        type="color"                                       
                                        value={_.get(formData, ['text_color']) || ''} 
                                        onChange={e => updateFormData(_.assign({}, formData, {text_color: _.get(e, ['target', 'value'])}))}
                                    />                      
                            </Form.Group>
                        </Col>
                    </Row>                     
                </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Cancel</Button>
                <Button 
                    type="submit"
                    form="edit-kit-form"
                >
                    {_.get(modalData, ['kit']) ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default EditKitModal