import { apiSlice } from "../../app/apiSlice";

export const userAPI = apiSlice.injectEndpoints({    
    tagTypes: ["User"],
    endpoints: builder => ({
        getCurrentUser: builder.query({
            query: () => 
                'user/current',
                method: 'GET'       
        })        
    })
})

export const  { useGetCurrentUserQuery } = userAPI