import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setToken, setTokenRefreshing, unsetCredentials } from '../features/auth/authSlice'
import _ from 'lodash'

const baseQuery = fetchBaseQuery({
    baseUrl: '/api/',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().authSlice.token
        if(token) {
            headers.set('authorization', `Bearer ${token}`)            
        }
        headers.set('X-Requested-With', 'XMLHttpRequest')
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {       
    let result = await baseQuery(args, api, extraOptions)
    if(_.get(result, ['error', 'data', 'error']) === 'Unauthorized'){
        if(api.getState().authSlice.tokenRefreshing) {    
            result = await setTimeout(() => baseQuery(args, api, extraOptions), 200) 
        }      
        api.dispatch(setTokenRefreshing(args))
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)
        const token = _.get(refreshResult, ['data', 'token'])
        if(_.get(refreshResult, ['data', 'user_id']) !== _.get(api.getState(), ['authSlice', 'user', 'id'])) {
            console.log('WRONG USER', args, refreshResult)
        }
        if (token) {                     
            api.dispatch(setToken(token))      
            result = await baseQuery(args, api, extraOptions)
        } else {            
            api.dispatch(unsetCredentials())
        }
    }
    return result    
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})