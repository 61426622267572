import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Modal, Form, Col, Row } from 'react-bootstrap'
import { isModalOpen, closeModal, getModalData } from '../../app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCurrentTeamQuery, useAddPlayerMutation, useUpdatePlayerMutation } from '../team/teamAPI'

const EditPlayerModal = props => {  
    const dispatch = useDispatch()
    const modalOpen = useSelector(isModalOpen('editPlayer'))  
    const team = _.get(useGetCurrentTeamQuery(), ['data', 'team'])    
    const [addPlayer] = useAddPlayerMutation()
    const [updatePlayer] = useUpdatePlayerMutation()
    const modalData = useSelector(getModalData('editPlayer'))  
    const [formData, updateFormData] = useState({})    
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({})

    useEffect(() => {
        if(_.isEmpty(formData) && modalData) {
            const player = _.get(modalData, ['player'])
            updateFormData(_.pick(player, ['name']))
        }        
    }, [formData, modalData])

    const handleAddPlayer = () => {        
        const handleResponse = response => {
            if(_.get(response, ['data', 'success'])) {
                    updateFormData({})
                    setError({})
                    dispatch(closeModal())   
            }else if(_.get(response, ['error', 'status']) === 409) {                
                setError(_.assign({}, error, {name: 'Player name exists in team'}))
            }      
            setValidated(false)    
        }
        const player = _.get(modalData, ['player'])
        if(player) {
            updatePlayer({player: _.assign({}, player, formData)})
            .then(handleResponse)                
        }else {
            addPlayer({
                team_id: team.id,
                player: formData
            })
            .then(handleResponse)      
        }     
    }

    const handleModalCancel = () => {
        updateFormData({})
        setError({})
        dispatch(closeModal())
    }

    const handleFormChange = (key, value) => {
        setValidated(false)
        setError(_.omit(error, [key]))
        let update = {}
        update[key] = value
        updateFormData(_.assign({}, formData, update))                                   
    }

    const handleSubmit = e => {      
        e.preventDefault()  
        e.stopPropagation()
        const form = e.currentTarget       
        if(form.checkValidity() && _.isEmpty(error)) {
            handleAddPlayer()
        }
        setValidated(true)
    }

    return (        
        <Modal 
            show={modalOpen} 
            centered
            size="sm"
        >
            <Modal.Header>          
                <Modal.Title>{(_.get(modalData, ['player']) ? 'Edit' : 'Add') + ' Player'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form 
                    noValidate 
                    validated={validated} 
                    id="edit-player-form"
                    onSubmit={handleSubmit}
                >
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Player Name</Form.Label>
                                <Form.Control                                         
                                    value={_.get(formData, ['name']) || ''} 
                                    onChange={e => handleFormChange('name', _.get(e, ['target', 'value']))}
                                    required
                                    name="name"     
                                    isInvalid={_.get(error, ['name'])}                       
                                />   
                                <Form.Control.Feedback type="invalid">
                                    {_.get(error, ['name']) || 'Please enter player name.'}
                                </Form.Control.Feedback>                    
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalCancel}>Cancel</Button>
                <Button 
                    type="submit"
                    form="edit-player-form"
                >
                    {_.get(modalData, ['player']) ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    )   
}

export default EditPlayerModal