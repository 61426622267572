import { Container, Row, Col, Carousel, Button } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse';
import carouselImg1 from '../assets/img/home_carousel_1.jpg'
import carouselImg2 from '../assets/img/home_carousel_2.jpg'
import carouselImg3 from '../assets/img/home_carousel_3.jpg'
import homeCode1 from '../assets/img/homeCode1.png'
import homeCode2 from '../assets/img/homeCode2.png'
import homeCode3 from '../assets/img/homeCode3.png'
import homeCode4 from '../assets/img/homeCode4.png'
import { useState } from 'react'
import { FaChevronDown } from "react-icons/fa6";
import matrixImg from '../assets/img/matrix.png'
import talkBall from '../assets/img/talkBall.png'
import { Link } from 'react-router-dom'

const CollapseCols = () => (
    <>
        <Col xs={3}><img src={homeCode2} alt="pitchinvader html code" /></Col>
        <Col xs={3}><img src={homeCode4} alt="pitchinvader face code" /></Col>
        <Col xs={3}><img src={matrixImg} alt="pitchinvader matrix code" /></Col>
        <Col xs={3}><img src={homeCode1} alt="pitchinvader js code" /></Col>
    </>
)

const Landing = () => {   
    const [collapse1Open, setCollapse1Open] = useState(false);
    const [collapse2Open, setCollapse2Open] = useState(false);

    return (
        <Container fluid className="home">
            <Row>
                <Col className="no-padding">
                    <Carousel indicators={false} interval={null}>
                        <Carousel.Item>  
                            <img src={carouselImg1} alt="pitchinvader pitch"/>
                            <Carousel.Caption>
                                <Container>
                                    <Row>
                                        <Col xs={{span:8, offset: 2}} className="bg-custom d-none d-sm-block py-3 px-0">
                                            <h1>Total</h1>
                                            <div className="border-top border-primary w-50 mx-auto my-3"></div>
                                            <h3>Team Analysis</h3>
                                            <Button size="lg" variant="primary">
                                                Demo
                                            </Button>
                                            <Button size="lg" variant="secondary">
                                                Register
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Caption>
                        </Carousel.Item>  
                        <Carousel.Item>  
                            <img src={carouselImg2} alt="pitchinvader ball"/>
                            <Carousel.Caption>
                                <Container>
                                    <Row>
                                        <Col xs={{span:8, offset: 2}} className="bg-custom d-none d-sm-block py-3 px-0">
                                            <h3>Match Day</h3>
                                            <p className="lead pb-3">Full control of your teams formations</p>
                                            <Button size="lg" variant="primary">
                                                Demo
                                            </Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Caption>
                        </Carousel.Item>  
                        <Carousel.Item>  
                            <img src={carouselImg3} alt="pitchinvader behind player"/>
                            <Carousel.Caption>
                                <Container>
                                    <Row className="justify-content-start text-left">
                                        <Col xs={{span:8, offset: 2}} className="bg-custom d-none d-sm-block py-3 px-0">
                                            <h3>Player Pages</h3>
                                            <p className="lead pb-3">Players get full access to their stats data</p>
                                            <Button size="lg" variant="secondary">
                                                Demo
                                            </Button>                                           
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Caption>
                        </Carousel.Item>  
                    </Carousel>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className="text-dark text-center mt-5">
                    <h1 className="pt-4">Pitch Invader</h1>
                    <div className="border-top border-primary w-25 mx-auto my-3"></div>
                    <p className="lead">Complete analysis and statistical overhaul for your sports team </p>
                </Col>    
            </Row>
            <Row className="my-5 mx-5 text-dark">
                <Col md={4} className="my-4">
                    <img src={homeCode1} alt="" className="w-100" /> 
                    <h4 className="my-4"> Statistical Advantage</h4>
                    <p className="text-justify">Pitch Invaders is able to provide 
                        statistical information to coaching staff about areas of the 
                        match day. This detailed information will help locate areas 
                        of weakness in their squad, which is in need of improvement. 
                        This will give the coaches advice through statistical scrutiny 
                        about what systems to use and/or their team selections.</p>
                    <Link to="/about">
                        <Button variant="outline-secondary">
                            Learn More
                        </Button>
                    </Link>
                </Col>
                <Col md={4} className="my-4">
                    <img src={homeCode2} alt="" className="w-100" /> 
                    <h4 className="my-4">Tried and Tested</h4>
                    <p className="text-justify">Our data analysis has been tried and 
                        tested over many seasons of amateur football. The more data 
                        entered the more accurate the systems will become. Over time 
                        our Data Learning Analytic Systems will become astonishingly 
                        accurate with our analysis of each player helping the 
                        coaching team alterations to the squads tactics.</p>
                    <Link to="/about">
                        <Button variant="outline-secondary">Learn More</Button>
                    </Link>
                </Col>
                <Col md={4} className="my-4">
                    <img src={homeCode3} alt="" className="w-100" /> 
                    <h4 className="my-4 justify-content-center">Be Well-Prepared</h4>
                    <p className="text-justify">The outcome of a good performance analysis means a well-defined 
                        coaching plan to improve a team’s or individuals performances. 
                        A coach can interpret a report or piece of analysis from Pitch 
                        Invaders Analyst engine and make adjustments to the team tactics 
                        depending on the statistical outcomes exposed.</p>
                    <Link to="/about">
                        <Button variant="outline-secondary">Our Story</Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>                    
                    <div
                        onClick={() => setCollapse1Open(!collapse1Open)}
                        aria-controls="collapse1"
                        aria-expanded={collapse1Open}
                        className="collapse-toggle"
                    >
                        <FaChevronDown />
                    </div>
                    <Collapse in={collapse1Open}>
                        <Container id="collapse1">
                            <Row>
                                <CollapseCols />
                            </Row>
                        </Container>
                    </Collapse>
                </Col>                   
            </Row>
            <Row>
                <Col xs={12} className="fixed-background">                   
                    <div className="fixed-wrap">
                        <div className="fixed" />
                    </div>   
                    <div className="text-center text-light">
                        <h1>AI Learning Algorithms</h1>
                        <h3 className="py-4">Take a glimpse inside to see how it all works</h3>
                        <Button variant="secondary" size="lg">Glimpse</Button>
                        <Button variant="primary" size="lg">Register</Button>
                    </div>                 
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div
                        onClick={() => setCollapse2Open(!collapse2Open)}
                        aria-controls="collapse2"
                        aria-expanded={collapse2Open}
                        className="collapse-toggle"
                    >
                        <FaChevronDown />
                    </div>
                    <Collapse in={collapse2Open}>
                        <Container id="collapse2">
                            <Row>
                                <CollapseCols />
                            </Row>
                        </Container>
                    </Collapse>
                </Col>                   
            </Row>
            <Row className="py-4 my-5 px-5 text-dark">
                <Col lg={4} className="mb-4 my-lg-auto">
                    <h1 className="text-dark font-weight-bold mb-3">Get your Team to the next level</h1>
                    <p className="mb-4">The basis of the Pitch Invader system is to 
                    assess the players performances, identifying areas of improvements, 
                    then to feedback information to our systems and displaying it in varies 
                    way for the coaching staff to easily understand and act upon. </p>
                    <Button variant="outline-dark" size="lg">More about us</Button>
                </Col>
                <Col lg={8}>
                    <img src={talkBall} alt="football talk" className="w-100" />
                </Col>
            </Row>
            <Row className="jumbo py-5 mb-0">               
                <Col md={7} lg={8} xl={9} className="my-auto px-5">
                    Are you interested in getting your team to the next level?
                </Col>
                <Col md={5} lg={4} xl={3} className="pt-4 pt-md-0">
                    <Link to="/contact">
                        <Button variant="secondary" size="lg" className="ml-2">
                            Contact Us Today
                        </Button>
                    </Link>
                </Col>
            </Row>
        </ Container>
    )
}

export default Landing